
import { Vue, Component } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import UserStore from "@/store/user.store";
import {
  isAscendAdmin,
  isAscendUser,
  isValidationServicesSpecialist,
  isValidationServicesManager,
  isValidationServicesUser,
  isSuperAdmin,
  isApManager,
  isApAdmin,
  isApSpecialist,
  isFinancialAccounting,
  isUserAdmin,
  userRolesIncludesRole,
  isProductionEnv,
  isLocalDevelopment,
  isCustomerFacingEnv,
  shouldMakeDataRequest,
  customPermissions
} from "@/helpers/user-roles";

@Component
export default class UserRolesMixin extends Vue {
  readonly userStore: UserStore = getModule(UserStore, this.$store);

  get user() {
    return this.userStore.getUser;
  }

  get userInitials() {
    if (this.user) {
      const userName = this.user.name.split(" ");
      return userName[0][0] + userName[1][0];
    } else {
      return "";
    }
  }

  get userFirstName() {
    if (this.user) {
      const userName = this.user.name.split(" ");
      return userName[0];
    } else {
      return "";
    }
  }

  get userLastName() {
    if (this.user) {
      const userName = this.user.name.split(" ");
      return userName[1];
    } else {
      return "";
    }
  }

  get userEmailAddress() {
    if (this.user) {
      return this.user.email?.trim();
    } else {
      return "";
    }
  }

  // internal-only roles
  get isAscendAdmin(): boolean {
    return isAscendAdmin(this.user);
  }

  get isAscendUser(): boolean {
    return isAscendUser(this.user);
  }

  get isValidationServicesSpecialist(): boolean {
    return isValidationServicesSpecialist(this.user);
  }

  get isValidationServicesManager(): boolean {
    return isValidationServicesManager(this.user);
  }

  get isValidationServicesUser(): boolean {
    return isValidationServicesUser(this.user);
  }

  get isSuperAdmin(): boolean {
    return isSuperAdmin(this.user);
  }

  // customer roles

  get isApManager(): boolean {
    return isApManager(this.user);
  }

  get isApAdmin(): boolean {
    return isApAdmin(this.user);
  }

  get isApSpecialist(): boolean {
    return isApSpecialist(this.user);
  }

  get isFinancialAccounting(): boolean {
    return isFinancialAccounting(this.user);
  }

  get isUserAdmin(): boolean {
    return isUserAdmin(this.user);
  }

  get shouldShowUserManagement(): boolean {
    return customPermissions.shouldShowUserManagement();
  }

  get shouldShowPayments(): boolean {
    return customPermissions.shouldShowPayments();
  }

  // determine if users roles includes one of the required roles for access
  userHasRequiredRole(rolesRequired: string[]) {
    return userRolesIncludesRole(this.user, rolesRequired);
  }

  // below 7 properties are based on whether the user's role grants them permission for document actions
  get canUserEditDocument(): boolean {
    // only Ascend Admin, AP Specialist, and VS Specialist/Manager roles can edit
    return (
      this.isAscendAdmin || this.isApSpecialist || this.isValidationServicesUser
    );
  }
  get canUserDeleteDocument(): boolean {
    return this.isAscendAdmin || this.isApManager;
  }
  get canUserCancelDocument(): boolean {
    return this.isAscendAdmin || this.isApSpecialist;
  }
  get canUserHoldDocument(): boolean {
    return this.isAscendAdmin || this.isApSpecialist || this.isApManager;
  }
  get canUserResumeDocument(): boolean {
    return this.isAscendAdmin || this.isApSpecialist || this.isApManager;
  }
  get canUserSubmitDoc(): boolean {
    return this.isAscendAdmin || this.isApSpecialist;
  }
  get canUserOnDemandSync() {
    return (
      this.isAscendAdmin ||
      this.isValidationServicesSpecialist ||
      this.isValidationServicesManager ||
      this.isApSpecialist
    );
  }

  // environment properties and special/custom permissions based on environment
  get isProductionEnv() {
    return isProductionEnv();
  }

  get isCustomerFacingEnv() {
    return isCustomerFacingEnv();
  }

  get isLocalDevelopment() {
    return isLocalDevelopment();
  }

  get shouldMakeDataRequest(): boolean {
    return shouldMakeDataRequest();
  }

  // special/custom permissions
  get isInternalDemoAccount() {
    return customPermissions.isInternalDemoAccount();
  }

  get hasEnterpriseProductSubscription() {
    return customPermissions.hasEnterpriseProductSubscription();
  }
}

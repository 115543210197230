



































































































import { Component } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { paymentCustomerService } from "@/services/payments/payment-customer.service";
import NavSidebarStore from "@/store/nav-sidebar.store";
import baseNavGroupOptions from "./_nav";
import configNavGroupOptions from "./_config-nav";
import paymentsNavGroupOptions from "./_payments-nav";
import paymentsOnboardingNavGroupOptions from "./_payments-onboarding-nav";
import { checkCurrentRouteAndRedirect } from "@/helpers/router-helpers";
import { customPermissions } from "@/helpers/user-roles";
import UserRolesMixin from "@/mixins/UserRoles.vue";

@Component
export default class NavSidebar extends UserRolesMixin {
  // store modules

  private readonly navSidebarStore: NavSidebarStore = getModule(
    NavSidebarStore,
    this.$store
  );

  // computed properties

  private get paymentsNavGroupsForCustomer() {
    return this.userStore.getIsPaymentsCustomer
      ? paymentsNavGroupOptions
      : paymentsOnboardingNavGroupOptions;
  }
  private get navGroups() {
    if (!this.shouldShowPayments) {
      return [...baseNavGroupOptions, ...configNavGroupOptions];
    } else {
      return [
        ...baseNavGroupOptions,
        ...this.paymentsNavGroupsForCustomer,
        ...configNavGroupOptions
      ];
    }
  }
  private get sidebarShow() {
    return this.navSidebarStore.getSidebarShow;
  }
  private set sidebarShow(showSidebar: boolean) {
    this.navSidebarStore.setSidebarShow(showSidebar);
  }
  private get sidebarMinimize() {
    return this.navSidebarStore.getSidebarMinimize;
  }
  private get mini() {
    return this.$vuetify.breakpoint.smAndDown || this.sidebarMinimize;
  }
  private set mini(sidebarMinimize: boolean) {
    this.navSidebarStore.setSidebarMinimize(sidebarMinimize);
  }

  // lifecycle methods
  async created() {
    if (this.shouldShowPayments) {
      await this.checkPaymentsCustomerStatus();
    }
  }

  // methods

  private async checkPaymentsCustomerStatus() {
    const customerStatus = await paymentCustomerService.getStatus();
    this.userStore.setIsPaymentsCustomer(customerStatus?.is_active ?? false);
  }

  private isAuthorized(navItem: { [key: string]: string | string[] }) {
    if (navItem.internalGroups && this.user) {
      let authorized = false;
      const customPermissionEvals: boolean[] = [];
      (navItem.internalGroups as string[]).forEach((group: string) => {
        customPermissionEvals.push(customPermissions[group]());
      });
      if (!customPermissionEvals.includes(false)) {
        authorized = true;
      }
      // also check is user role permissions are needed
      const authorizedRoles = navItem.authorize ?? [];
      if (authorizedRoles.length > 0) {
        authorized =
          authorized && this.userHasRequiredRole(authorizedRoles as string[]);
      }
      return authorized;
    } else if (this.user) {
      return (
        // route is open to any role
        !("authorize" in navItem) ||
        // user has correct role for route
        this.userHasRequiredRole(navItem.authorize as string[])
      );
    } else {
      return false;
    }
  }

  toggleDrawer() {
    this.navSidebarStore.setSidebarMinimize(!this.sidebarMinimize);
  }

  collapseNavGroups() {
    for (const title in this.$refs) {
      (this.$refs[title] as any)[0].isActive = false;
    }
  }

  reroute(event: Event, router: any, title: string, path: any) {
    const isActive = (this.$refs[title] as any)[0].isActive ?? false;
    // if nav group is going to be expanded due to change in path, prevent toggle from collapsing it
    if (!isActive && this.$route.path !== path) {
      event.stopPropagation();
    }
    checkCurrentRouteAndRedirect(router, path);
  }
}

export interface DocumentRuleRequest {
  FromInvID?: string;
  SupplierID: string;
  CompanyID: string;
  CustomerAccountNumber: string;
}

export enum DocumentRuleModalMode {
  CREATE = "CREATE",
  UPDATE = "UPDATE"
}

export enum DocumentRuleModalLaunchMode {
  DOCUMENT_ENTRY = "DocumentEntry",
  DOCUMENT_RULES_LIST = "DocumentRulesList"
}

export enum DocumentRuleType {
  CUSTOM_RULE = "PROTOTYPE",
  PREVIOUS_INVOICE = "PREVIOUS",
  LINE_EXTRACTION = "NPOLINES",
  NO_AUTOMATION = "NONE"
}

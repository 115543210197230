export const randomNumberGenerator = (): number => {
  const crypto = window.crypto || (window["msCrypto"] as Crypto);
  const array = new Uint32Array(1);
  return crypto.getRandomValues(array)[0];
};

export function getDecimals(inputValue: string | number): number {
  const value = inputValue.toString();
  const parts = value.includes(".") ? value.split(".") : [value];
  const splitLength = parts.length;
  let rightToLeftZeroCounter = 0;
  //IF the number do not has decimal part
  if (splitLength < 2) {
    return 2;
  }
  const decimalPart = parts[1];
  const decimalPartLength = decimalPart.length;
  //IF the number has decimal part and the decimal
  //part length is
  if (decimalPartLength <= 1) {
    return 2;
  }
  let count = 1;
  let currentDecimal = decimalPart[decimalPartLength - count];
  while (currentDecimal == "0") {
    rightToLeftZeroCounter++;
    currentDecimal = decimalPart[decimalPartLength - ++count];
  }
  const decimalsToDisplay = decimalPartLength - rightToLeftZeroCounter;
  const result = decimalsToDisplay > 1 ? decimalsToDisplay : 2;
  return result > 6 ? 6 : result; // max 6 decimal places in database
}

/**
 *
 * @param value
 * @returns
 * @description convert string to decimal number and truncates trailing zeros
 */
export function stringToDecimal(value: string): number {
  let parsedValue = parseFloat(value);
  if (Number.isNaN(parsedValue)) {
    parsedValue = 0;
  }

  return parsedValue;
}

type OperationResult = { type: string; result: number };
const ARITHMETIC_TYPES = {
  ADD: "add",
  SUB: "sub",
  MUL: "mul",
  DIV: "div"
};

/**
 * @author Ramiro Dutto Luquez <rluquez@ascendsoftware.com>
 * @contributors
 */
export class SafeMath {
  // Decimal precision is the total decimals taked in count
  // to perform the arithmetic operation. Decimal precision does not has
  // any impact in the number of decilas returned by the operation.
  private static _defaultDecimalPrecision: number | string = 6;

  /**
   *
   * @param arithmeticFunc
   * @returns number with significant digits
   */
  private static _safeOperation = (
    arithmeticOperation: (operand1: number, operand2: number) => OperationResult
  ) => (
    a: number | string,
    b: number | string,
    decimalPrecision: number | string
  ): number => {
    // convert a and b to number and decimalPrecision to int
    if (typeof a === "string") a = parseFloat(a);
    if (typeof b === "string") b = parseFloat(b);
    if (typeof decimalPrecision === "string")
      decimalPrecision = parseInt(decimalPrecision);

    // round decimalPrecision to nearest int 0.4 => 0, 0.5 => 1
    decimalPrecision = Math.round(decimalPrecision);

    // get the decimal separator displacer
    const decimalDisplacer = 10 ** decimalPrecision;
    a = Math.trunc(a * decimalDisplacer);
    b = Math.trunc(b * decimalDisplacer);

    // perform the operation
    const { type, result }: OperationResult = arithmeticOperation(a, b);
    let fixedValue;

    if (type === ARITHMETIC_TYPES.ADD || type === ARITHMETIC_TYPES.SUB) {
      fixedValue = Math.trunc(result) / decimalDisplacer;
    }

    if (type === ARITHMETIC_TYPES.MUL) {
      fixedValue = Math.trunc(result / decimalDisplacer) / decimalDisplacer;
    }

    if (type === ARITHMETIC_TYPES.DIV) {
      fixedValue = Math.trunc(result * decimalDisplacer) / decimalDisplacer;
    }

    return fixedValue;
  };

  private static _add = (a: number, b: number): OperationResult => ({
    type: ARITHMETIC_TYPES.ADD,
    result: a + b
  });

  private static _sub = (a: number, b: number): OperationResult => ({
    type: ARITHMETIC_TYPES.SUB,
    result: a - b
  });

  private static _mul = (a: number, b: number): OperationResult => ({
    type: ARITHMETIC_TYPES.MUL,
    result: a * b
  });

  private static _div = (a: number, b: number): OperationResult => ({
    type: ARITHMETIC_TYPES.DIV,
    result: a / b
  });

  /**
   * Performs arithmetic addition
   * safeAdd => addition = augend + addend
   * @author Ramiro Dutto Luquez <rluquez@ascendsoftware.com>
   * @contributors
   * @param {number | string } augend
   * @param {number | string } addend
   * @param {number | string } [decimalPrecision=6]
   * @returns {number}
   */
  static safeAdd(
    augend: number | string,
    addend: number | string,
    decimalPrecision: number | string = this._defaultDecimalPrecision
  ) {
    return this._safeOperation(this._add)(augend, addend, decimalPrecision);
  }

  /**
   * Performs arithmetic subtraction
   * safeSub => subtraction = minuend - subtrahend
   * @author Ramiro Dutto Luquez <rluquez@ascendsoftware.com>
   * @contributors
   * @param {number | string } minuend
   * @param {number | string } subtrahend
   * @param {number | string } [decimalPrecision=6]
   * @returns {number}
   */
  static safeSub(
    minuend: number | string,
    subtrahend: number | string,
    decimalPrecision: number | string = this._defaultDecimalPrecision
  ) {
    return this._safeOperation(this._sub)(
      minuend,
      subtrahend,
      decimalPrecision
    );
  }

  /**
   * Performs arithmetic multiplication
   * safeMul => multiplication = multiplier * multiplicand
   * @author Ramiro Dutto Luquez <rluquez@ascendsoftware.com>
   * @contributors
   * @param {number | string } multiplier
   * @param {number | string } multiplicand
   * @param {number | string } [decimalPrecision=6]
   * @returns {number}
   */
  static safeMul(
    multiplier: number | string,
    multiplicand: number | string,
    decimalPrecision: number | string = this._defaultDecimalPrecision
  ) {
    return this._safeOperation(this._mul)(
      multiplier,
      multiplicand,
      decimalPrecision
    );
  }

  /**
   * Performs arithmetic division
   * safeDiv => division = dividend / divisor
   * @author Ramiro Dutto Luquez <rluquez@ascendsoftware.com>
   * @contributors
   * @param {number | string } dividend
   * @param {number | string } divisor
   * @param {number | string } [decimalPrecision=6]
   * @returns {number}
   */
  static safeDiv(
    dividend: number | string,
    divisor: number | string,
    decimalPrecision: number | string = this._defaultDecimalPrecision
  ) {
    return this._safeOperation(this._div)(dividend, divisor, decimalPrecision);
  }
}

import { RouteConfig } from "vue-router";
import AppRoles from "@/shared/constants/application-roles";

const analyticsMetadata = {
  title: "Analytics",
  requiresAuth: true,
  authorize: [
    AppRoles.FINANCIAL_ACCOUNTING,
    AppRoles.AP_MANAGER,
    AppRoles.VS_MANAGER,
    AppRoles.ASCEND_ADMIN
  ]
};

export const analyticsRoutes: Array<RouteConfig> = [
  {
    path: "/analytics/recognition",
    name: "Recognition Analytics",
    component: () =>
      import(
        /* webpackChunkName: "RecognitionAnalytics" */ "@/views/analytics/RecognitionAnalytics.vue"
      ),
    meta: analyticsMetadata
  },
  {
    path: "/analytics/recognition-rates",
    name: "Recognition Rate Analytics",
    component: () =>
      import(
        /* webpackChunkName: "RecognitionRateAnalytics" */ "@/views/analytics/RecognitionRateAnalytics.vue"
      ),
    meta: analyticsMetadata
  },
  {
    path: "/analytics/invoice-volume",
    name: "Invoice Volume Analytics",
    component: () =>
      import(
        /* webpackChunkName: "InvoiceVolumeAnalytics" */ "@/views/analytics/InvoiceVolumeAnalytics.vue"
      ),
    meta: analyticsMetadata
  },
  {
    path: "/analytics/invoices",
    name: "Invoice List Analytics",
    component: () =>
      import(
        /* webpackChunkName: "InvoiceAnalytics" */ "@/views/analytics/InvoiceAnalytics.vue"
      ),
    meta: analyticsMetadata
  },
  {
    path: "/analytics/touchless-processing",
    name: "Touchless Processing",
    component: () =>
      import(
        /* webpackChunkName: "TouchlessProcessing" */ "@/views/analytics/TouchlessProcessing.vue"
      ),
    meta: analyticsMetadata
  },
  {
    path: "/analytics/licensing",
    name: "Licensing",
    component: () =>
      import(
        /* webpackChunkName: "Licensing" */ "@/views/analytics/Licensing.vue"
      ),
    meta: {
      ...analyticsMetadata,
      title: "Licensing",
      customPermissions: ["shouldShowLicensingAnalytics"]
    }
  }
];

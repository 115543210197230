







import UserRolesMixin from "@/mixins/UserRoles.vue";
import { Component } from "vue-property-decorator";
import moment from "moment";

@Component
export default class UserChatRequestHeader extends UserRolesMixin {
  private get userName(): string {
    return this.user?.name ?? "";
  }

  private get currentTimestamp(): string {
    const locale = Intl.DateTimeFormat().resolvedOptions().locale;
    moment.locale(locale.toLowerCase());
    return moment().format("LT");
  }
}

import { RouteConfig } from "vue-router";
import AppRoles from "@/shared/constants/application-roles";

export const settingsRoutes: Array<RouteConfig> = [
  {
    path: "/settings/document",
    name: "Documents Setting",
    component: () =>
      import(
        /* webpackChunkName: "DocumentSettings" */ "@/views/configurations/DocumentsSettings.vue"
      ),
    meta: {
      title: "Configurations",
      requiresAuth: true,
      authorize: [AppRoles.AP_ADMIN, AppRoles.ASCEND_ADMIN]
    }
  },
  {
    path: "/settings/application",
    name: "Application Setting",
    component: () =>
      import(
        /* webpackChunkName: "ApplicationSettings" */ "@/views/configurations/ApplicationSettings.vue"
      ),
    meta: {
      title: "Configurations",
      requiresAuth: true,
      authorize: [AppRoles.AP_ADMIN, AppRoles.ASCEND_ADMIN]
    }
  },
  {
    path: "/settings/groupings",
    name: "Document Groups",
    component: () =>
      import(
        /* webpackChunkName: "DocumentGroups" */ "@/views/configurations/DocumentGroups.vue"
      ),
    meta: {
      title: "Configurations",
      requiresAuth: true,
      authorize: [
        AppRoles.AP_ADMIN,
        AppRoles.AP_MANAGER,
        AppRoles.ASCEND_ADMIN
      ],
      customPermissions: ["hasEnterpriseProductSubscription"]
    }
  },
  {
    path: "/settings/field",
    name: "Field Settings",
    component: () =>
      import(
        /* webpackChunkName: "FieldSettings" */ "@/views/configurations/FieldSettings.vue"
      ),
    meta: {
      title: "Configurations",
      requiresAuth: true,
      authorize: [AppRoles.AP_ADMIN, AppRoles.ASCEND_ADMIN],
      customPermissions: ["shouldShowFieldSettings"]
    }
  }
];




























































import { Vue, Component, Prop } from "vue-property-decorator";
import ConfirmationPrimaryButton from "@/components/design-system/buttons/ConfirmationPrimaryButton.vue";
import PrimaryButton from "@/components/design-system/buttons/PrimaryButton.vue";
import SecondaryButton from "@/components/design-system/buttons/SecondaryButton.vue";

@Component({
  components: {
    "confirmation-primary-button": ConfirmationPrimaryButton,
    "primary-button": PrimaryButton,
    "secondary-button": SecondaryButton
  }
})
export default class SimpleConfirmationModal extends Vue {
  @Prop() isModalDisplayed!: boolean;
  @Prop() handleCloseModal!: Function;
  @Prop() handleConfirmSelection!: Function;
  @Prop() handleOptionalSelection!: Function;
  @Prop() modalTitle!: string;
  @Prop({ default: "" }) modalSubtitle!: string;
  @Prop() modalText!: string;
  @Prop() confirmButtonText!: string;
  @Prop() cancelButtonText!: string;
  @Prop() optionalButtonText!: string;
  @Prop({ default: "confirmation" }) modalType!: string;
  @Prop({ default: () => [] }) modalBodyList!: string[];
  @Prop({ default: "40%" }) width?: string;
  @Prop({ default: "" }) modalClass?: string;
  @Prop({ default: false }) isConfirmButtonDisabled!: boolean;
}

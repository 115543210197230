export default [
  {
    title: "Payments",
    icon: "mdi-wallet",
    href: "/payments/upgrade",
    group: "payments",
    internalGroups: ["shouldShowPayments", "isNotActiveOnPayments"],
    subGroup: [
      {
        title: "Upgrade",
        href: "/payments/upgrade"
      }
    ]
  }
];

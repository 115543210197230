import AppRoles from "@/shared/constants/application-roles";

export default [
  {
    title: "Users",
    icon: "mdi-account-circle",
    href: "/users/configuration",
    group: "users",
    internalGroups: ["shouldShowUserManagement"],
    subGroup: [
      {
        title: "Manage Users",
        href: "/users/configuration",
        internalGroups: ["shouldShowUserManagement"]
      }
    ]
  },
  {
    title: "Settings",
    icon: "mdi-cog",
    href: "/settings/groupings",
    group: "settings",
    authorize: [AppRoles.AP_ADMIN, AppRoles.AP_MANAGER, AppRoles.ASCEND_ADMIN],
    subGroup: [
      {
        title: "Document Groups",
        href: "/settings/groupings",
        authorize: [
          AppRoles.AP_MANAGER,
          AppRoles.AP_ADMIN,
          AppRoles.ASCEND_ADMIN
        ],
        internalGroups: ["hasEnterpriseProductSubscription"]
      },
      {
        title: "Document Settings",
        href: "/settings/document",
        authorize: [AppRoles.AP_ADMIN, AppRoles.ASCEND_ADMIN]
      },
      {
        title: "Application Settings",
        href: "/settings/application",
        authorize: [AppRoles.AP_ADMIN, AppRoles.ASCEND_ADMIN]
      },
      {
        title: "Field Settings",
        href: "/settings/field",
        authorize: [AppRoles.AP_ADMIN, AppRoles.ASCEND_ADMIN],
        internalGroups: ["shouldShowFieldSettings"]
      },
      {
        title: "Demo Settings",
        href: "/internal-only/demo-settings",
        internalGroups: ["isInternalDemoAccount"]
      }
    ]
  },
  {
    title: "Tenants",
    icon: "mdi-account-group",
    href: "/internal-only/tenants",
    group: "tenants",
    internalGroups: ["shouldShowTenantManagement"],
    subGroup: [
      {
        title: "Manage Tenants",
        href: "/internal-only/tenants",
        internalGroups: ["shouldShowTenantManagement"]
      }
    ]
  }
];








import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class ConfirmationPrimaryButton extends Vue {
  @Prop() handleClick!: Function;
  @Prop() buttonText!: string;
}

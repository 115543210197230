import { app } from "@/main";
import { PaymentCustomerStatusResponse } from "@/models/payments/customers/payment-customer-status-response";
import { PaymentCustomerStatus } from "@/models/payments/customers/payment-customer-status";
import { PaymentsOnboardingFormStatus } from "@/models/payments/customers/onboarding/payments-onboarding-form-status";
import { InitiateOnboardingResponse } from "@/models/payments/customers/onboarding/initiate-onboarding-response";
import { CancelOnboardingResponse } from "@/models/payments/customers/onboarding/cancel-onboarding-response";
import { CompleteOnboardingResponse } from "@/models/payments/customers/onboarding/complete-onboarding-response";
import { OnboardingApplication } from "@/models/payments/customers/onboarding/onboarding-application";
import PaymentCustomerStatuses from "@/shared/constants/payment-customer-statuses";

class PaymentCustomerService {
  private baseURL = "api/payments/customers";

  public async getStatus(): Promise<PaymentCustomerStatus> {
    const apiResponse = await app.$ravenapi.get(
      this.baseURL.concat("/statuses")
    );

    if (apiResponse?.data as PaymentCustomerStatusResponse) {
      return new PaymentCustomerStatus(
        apiResponse.data?.payments_status_type?.toUpperCase() ==
          PaymentCustomerStatuses.ACTIVE &&
          apiResponse.data?.provider_onboarding_status_type?.toUpperCase() ==
            PaymentCustomerStatuses.ACTIVE,
        apiResponse.data?.onboarding_form_status
      );
    } else {
      return new PaymentCustomerStatus(
        false,
        new PaymentsOnboardingFormStatus(false, "", "")
      );
    }
  }

  public async initiateOnboardingApplication(): Promise<
    InitiateOnboardingResponse
  > {
    const apiResponse = await app.$ravenapi.put(
      this.baseURL.concat("/onboardings")
    );
    return apiResponse?.data;
  }

  public async cancelOnboardingApplication(): Promise<
    CancelOnboardingResponse
  > {
    const apiResponse = await app.$ravenapi.delete(
      this.baseURL.concat("/onboardings")
    );
    return apiResponse?.data;
  }

  public async submitOnboardingApplication(
    application: OnboardingApplication
  ): Promise<CompleteOnboardingResponse> {
    const apiResponse = await app.$ravenapi.post(
      this.baseURL.concat("/onboardings"),
      application
    );
    return apiResponse?.data;
  }
}

export const paymentCustomerService = new PaymentCustomerService();

import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { cloneDeep } from "lodash";
import moment from "moment";
import { InputObject } from "@/models/document-entry/input-object";
import { TableColumnSelector } from "@/models/payments/table-column-selector";
import { SettlementRunActivityRecord } from "@/models/payments/settlement-run-activity/settlement-run-activity-record";
import { PaymentPaginationMetadata } from "@/models/payments/payment-pagination-metadata";

@Module({ name: "SettlementRunActivityStore", namespaced: true })
class SettlementRunActivityStore extends VuexModule {
  private customerID: number | null = null;
  private startDate = moment()
    .subtract(1, "months")
    .format()
    .toString()
    .substring(0, 10);
  private endDate = moment()
    .format()
    .toString()
    .substring(0, 10);
  private companyOptions: InputObject[] = [];
  private paymentTypeOptions: InputObject[] = [];
  private srStatusOptions: InputObject[] = [];
  private selectedCompany: string | null = null;
  private selectedPaymentType: string | null = null;
  private selectedSettlementRunStatus: string | null = null;
  private readonly defaultHeaders: TableColumnSelector[] = [
    {
      align: "end",
      text: "Settlement Run ID",
      sortable: true,
      value: "payment_message_id",
      selected: true
    },
    {
      align: "start",
      text: "Settlement Run Name",
      sortable: true,
      value: "payment_message",
      selected: true
    },
    {
      align: "start",
      text: "Run Date",
      sortable: true,
      value: "create_date",
      selected: true
    },
    {
      align: "end",
      text: "Run Date Sequence",
      sortable: true,
      value: "create_date_sequence",
      selected: true
    },
    {
      align: "end",
      text: "Payment Sum",
      sortable: true,
      value: "payments_sum",
      selected: true
    },
    {
      align: "start",
      text: "Received Date",
      sortable: true,
      value: "received_at",
      selected: true
    },
    {
      align: "end",
      text: "Number of Payments",
      sortable: true,
      value: "payment_count",
      selected: true
    },
    {
      align: "start",
      text: "Status",
      sortable: true,
      value: "payment_file_status_type",
      selected: true
    },
    {
      align: "end",
      text: "Check Payments Count",
      sortable: true,
      value: "check_payments_count",
      selected: false
    },
    {
      align: "end",
      text: "Check Payments Sum",
      sortable: true,
      value: "check_payments_sum",
      selected: false
    },
    {
      align: "end",
      text: "ACH Payments Count",
      sortable: true,
      value: "direct_deposit_payments_count",
      selected: false
    },
    {
      align: "end",
      text: "ACH Payments Sum",
      sortable: true,
      value: "direct_deposit_payments_sum",
      selected: false
    },
    {
      align: "end",
      text: "Virtual Card Payments Count",
      sortable: true,
      value: "vcard_payment_count",
      selected: false
    },
    {
      align: "end",
      text: "Virtual Card Payments Sum",
      sortable: true,
      value: "vcard_payment_sum",
      selected: false
    }
  ];
  private availableHeaders: TableColumnSelector[] = cloneDeep(
    this.defaultHeaders
  );
  private settlementRuns: SettlementRunActivityRecord[] = [];
  private paginationMetadata: PaymentPaginationMetadata | null = null;
  private pagesLoaded: number[] = [];
  private selectedSettlementRun: SettlementRunActivityRecord | null = null;

  @Mutation
  private setStartDateMutation(date: string) {
    this.startDate = date;
  }

  @Mutation
  private setEndDateMutation(date: string) {
    this.endDate = date;
  }

  @Mutation
  private setCompanyOptionsMutation(options: InputObject[]) {
    this.companyOptions = options;
  }

  @Mutation
  private setPaymentTypeOptionsMutation(options: InputObject[]) {
    this.paymentTypeOptions = options;
  }

  @Mutation
  private setSettlementRunStatusOptionsMutation(options: InputObject[]) {
    this.srStatusOptions = options;
  }

  @Mutation
  private setSelectedCompanyMutation(option: string | null) {
    this.selectedCompany = option;
  }

  @Mutation
  private setSelectedPaymentTypeMutation(option: string | null) {
    this.selectedPaymentType = option;
  }

  @Mutation
  private setSelectedSettlementRunStatusMutation(option: string | null) {
    this.selectedSettlementRunStatus = option;
  }

  @Mutation
  private setAvailableHeadersMutation(tableHeaders: TableColumnSelector[]) {
    this.availableHeaders = tableHeaders;
  }

  @Mutation
  private setSettlementRunsMutation(
    settlementRuns: SettlementRunActivityRecord[]
  ) {
    this.settlementRuns = settlementRuns;
  }

  @Mutation
  private setPaginationMetadataMutation(
    metadata: PaymentPaginationMetadata | null
  ) {
    this.paginationMetadata = metadata;
  }

  @Mutation
  private setPagesLoadedMutation(pagesLoaded: number[]) {
    this.pagesLoaded = pagesLoaded;
  }

  @Mutation
  private setSelectedSettlementRunMutation(
    settlementRun: SettlementRunActivityRecord | null
  ) {
    this.selectedSettlementRun = settlementRun;
  }

  @Mutation
  private resetStateMutation() {
    this.startDate = moment()
      .subtract(1, "months")
      .format()
      .toString()
      .substring(0, 10);
    this.endDate = moment()
      .format()
      .toString()
      .substring(0, 10);
    this.companyOptions = [];
    this.paymentTypeOptions = [];
    this.srStatusOptions = [];
    this.selectedCompany = null;
    this.selectedPaymentType = null;
    this.selectedSettlementRunStatus = null;
    this.settlementRuns = [];
    this.paginationMetadata = null;
    this.pagesLoaded = [];
    this.selectedSettlementRun = null;
  }

  @Mutation
  private setCustomerMutation(customerID: number) {
    this.customerID = customerID;
  }

  @Action({ rawError: true })
  public setStartDate(date: string) {
    this.context.commit("setStartDateMutation", date);
  }

  @Action({ rawError: true })
  public setEndDate(date: string) {
    this.context.commit("setEndDateMutation", date);
  }

  @Action({ rawError: true })
  public setCompanyOptions(options: InputObject[]) {
    this.context.commit("setCompanyOptionsMutation", options);
  }

  @Action({ rawError: true })
  public setPaymentTypeOptions(options: InputObject[]) {
    this.context.commit("setPaymentTypeOptionsMutation", options);
  }

  @Action({ rawError: true })
  public setSettlementRunStatusOptions(options: InputObject[]) {
    this.context.commit("setSettlementRunStatusOptionsMutation", options);
  }

  @Action({ rawError: true })
  public setSelectedCompany(option: string | null) {
    this.context.commit("setSelectedCompanyMutation", option);
  }

  @Action({ rawError: true })
  public setSelectedPaymentType(option: string | null) {
    this.context.commit("setSelectedPaymentTypeMutation", option);
  }

  @Action({ rawError: true })
  public setSelectedSettlementRunStatus(option: string | null) {
    this.context.commit("setSelectedSettlementRunStatusMutation", option);
  }

  @Action({ rawError: true })
  public setAvailableHeaders(tableHeaders: TableColumnSelector[]) {
    this.context.commit("setAvailableHeadersMutation", tableHeaders);
  }

  @Action({ rawError: true })
  public setSettlementRuns(payments: SettlementRunActivityRecord[]) {
    this.context.commit("setSettlementRunsMutation", payments);
  }

  @Action({ rawError: true })
  public setPaginationMetadata(metadata: PaymentPaginationMetadata | null) {
    this.context.commit("setPaginationMetadataMutation", metadata);
  }

  @Action({ rawError: true })
  public setPagesLoaded(pagesLoaded: number[]) {
    this.context.commit("setPagesLoadedMutation", pagesLoaded);
  }

  @Action({ rawError: true })
  public setSelectedSettlementRun(
    settlementRun: SettlementRunActivityRecord | null
  ) {
    this.context.commit("setSelectedSettlementRunMutation", settlementRun);
  }

  @Action({ rawError: true })
  public resetState() {
    this.context.commit("resetStateMutation");
  }

  @Action({ rawError: true })
  public setCustomer(customerID: number | null) {
    this.context.commit("setCustomerMutation", customerID);
  }

  public get getStartDate(): string {
    return this.startDate;
  }

  public get getEndDate(): string {
    return this.endDate;
  }

  public get getCompanyOptions(): InputObject[] {
    return this.companyOptions;
  }

  public get getPaymentTypeOptions(): InputObject[] {
    return this.paymentTypeOptions;
  }

  public get getSettlementRunStatusOptions(): InputObject[] {
    return this.srStatusOptions;
  }

  public get getSelectedCompany(): string | null {
    return this.selectedCompany;
  }

  public get getSelectedPaymentType(): string | null {
    return this.selectedPaymentType;
  }

  public get getSelectedSettlementRunStatus(): string | null {
    return this.selectedSettlementRunStatus;
  }

  public get getDefaultHeaders(): TableColumnSelector[] {
    return this.defaultHeaders;
  }

  public get getAvailableHeaders(): TableColumnSelector[] {
    return this.availableHeaders;
  }

  public get getSettlementRuns(): SettlementRunActivityRecord[] {
    return this.settlementRuns;
  }

  public get getPaginationMetadata(): PaymentPaginationMetadata | null {
    return this.paginationMetadata;
  }

  public get getPagesLoaded(): number[] {
    return this.pagesLoaded;
  }

  public get getSelectedSettlementRun(): SettlementRunActivityRecord | null {
    return this.selectedSettlementRun;
  }

  public get getCustomerID(): number | null {
    return this.customerID;
  }
}

export default SettlementRunActivityStore;

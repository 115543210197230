



































import { Vue, Component } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import UserStore from "@/store/user.store";
import { checkCurrentRouteAndRedirect } from "@/helpers/router-helpers";

@Component
export default class CustomerSelector extends Vue {
  private isChangeCustomerMenuOpen = false;

  private readonly userStore = getModule(UserStore, this.$store);
  private get selectedCustomer() {
    return this.userStore.getSelectedCustomer;
  }

  redirectToChooseCustomer() {
    checkCurrentRouteAndRedirect(
      this.$router,
      "/internal-only/select-customer"
    );
  }
}

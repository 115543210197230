

















































import { Component } from "vue-property-decorator";
import AscendLogo from "@/components/design-system/icons/AscendLogo.vue";
import CustomerSelector from "@/components/internal-only/CustomerSelector.vue";
import UserRolesMixin from "@/mixins/UserRoles.vue";

@Component({
  components: {
    "ascend-logo": AscendLogo,
    "customer-selector": CustomerSelector
  }
})
export default class ErrorLayout extends UserRolesMixin {
  private shouldShowNotificationCenter: boolean = this.$launchDarkly.variation(
    "show-notification-center",
    false
  );

  private get authenticated() {
    return this.userStore.getAuthenticationState;
  }

  private get showCustomerSelectorInAppBar() {
    if (this.user) {
      return this.isAscendUser && this.isCustomerFacingEnv;
    } else {
      return false;
    }
  }

  async login() {
    await this.$auth.signInWithRedirect();
  }

  async logout() {
    await this.$auth.signOut({
      postLogoutRedirectUri: window.location.origin + "/logout"
    });
    // clear tokens
    this.$auth.tokenManager.clear();
    // update launch-darkly for non-authenticated user
    await this.$launchDarkly.identify({
      anonymous: true
    });
  }
}

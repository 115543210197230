/* eslint-disable @typescript-eslint/camelcase */

import { PaymentsOnboardingFormStatus } from "./onboarding/payments-onboarding-form-status";

export class PaymentCustomerStatus {
  public is_active: boolean;
  public onboarding_form_status: PaymentsOnboardingFormStatus;

  constructor(
    is_active: boolean,
    onboarding_form_status: PaymentsOnboardingFormStatus
  ) {
    this.is_active = is_active;
    this.onboarding_form_status = onboarding_form_status;
  }
}

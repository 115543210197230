






































































import { Vue, Component } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import NavSidebarStore from "@/store/nav-sidebar.store";
import { checkCurrentRouteAndRedirect } from "@/helpers/router-helpers";
import { validationServicesService } from "@/services/invoices-list/validation-services.service";
import { CustomerSummaryItem } from "@/models/validation-services/customers-list";
import UserRolesMixin from "@/mixins/UserRoles.vue";

@Component({
  components: {}
})
export default class ValidationServicesNavSidebar extends UserRolesMixin {
  // reactive properties
  private loadingCustomerList = false;
  private customerList: CustomerSummaryItem[] = [];
  private searchTermFilter = "";

  // readonly store modules
  private readonly navSidebarStore: NavSidebarStore = getModule(
    NavSidebarStore,
    this.$store
  );

  // computed properties
  private get searchTermFilteredList(): CustomerSummaryItem[] {
    return this.customerList.filter((customer: CustomerSummaryItem) => {
      return (
        customer.name
          .toLowerCase()
          .includes(this.searchTermFilter.toLowerCase()) ||
        customer.id.toString().includes(this.searchTermFilter)
      );
    });
  }
  private get selectedCustomer() {
    return this.userStore.getSelectedCustomer;
  }
  private get vsSidebarShow() {
    return this.navSidebarStore.getSidebarShow;
  }
  private set vsSidebarShow(showSidebar: boolean) {
    this.navSidebarStore.setSidebarShow(showSidebar);
  }
  private get vsSidebarMinimize() {
    return this.navSidebarStore.getSidebarMinimize;
  }
  private get vsMini() {
    return this.$vuetify.breakpoint.smAndDown || this.vsSidebarMinimize;
  }
  private set vsMini(sidebarMinimize: boolean) {
    this.navSidebarStore.setSidebarMinimize(sidebarMinimize);
  }

  // lifecycle methods
  async created() {
    await this.getSideBarValidationServicesData();
  }

  // methods
  private async getSideBarValidationServicesData() {
    this.loadingCustomerList = true;
    this.customerList = await validationServicesService.getCustomersWithVSInvoices();
    this.loadingCustomerList = false;
  }
  toggleDrawer() {
    this.navSidebarStore.setSidebarMinimize(!this.vsSidebarMinimize);
  }
  reroute(router: any, path: any) {
    checkCurrentRouteAndRedirect(router, path);
  }
  redirectToVsCustomerPage(navItem: CustomerSummaryItem) {
    this.userStore.setSelectedCustomer({
      id: navItem.id,
      description: navItem.name,
      displayValue: navItem.name,
      region: navItem.region
    });
    checkCurrentRouteAndRedirect(
      this.$router,
      `/validation-services/${navItem.id}`
    );
  }
}

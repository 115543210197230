// IMPORTANT - ALWAYS MAKE CORRESPONDING UPDATES IN SRC/MIXINS/USERROLES.VUE

import { app } from "@/main";
import AppRoles from "@/shared/constants/application-roles";
import store from "@/store";

// internal-only roles

export const isSuperAdmin = (user: any) => {
  return user.groups?.includes("SUPERUSERS") ?? false;
};

export const isAscendAdmin = (user: any) => {
  return (
    (user.groups?.includes(AppRoles.ASCEND_USER) ?? false) &&
    (user.approle?.includes(AppRoles.ASCEND_ADMIN) ?? false)
  );
};

export const isAscendUser = (user: any) => {
  return user.groups?.includes(AppRoles.ASCEND_USER) ?? false;
};

export const isValidationServicesSpecialist = (user: any) => {
  return (
    (user.groups?.includes(AppRoles.ASCEND_USER) ?? false) &&
    (user.approle?.includes(AppRoles.VS_SPECIALIST) ?? false)
  );
};

export const isValidationServicesManager = (user: any) => {
  return (
    (user.groups?.includes(AppRoles.ASCEND_USER) ?? false) &&
    (user.approle?.includes(AppRoles.VS_MANAGER) ?? false)
  );
};

export const isValidationServicesUser = (user: any) => {
  return (
    user.groups?.includes(AppRoles.ASCEND_USER) &&
    ((user.approle?.includes(AppRoles.VS_MANAGER) ?? false) ||
      (user.approle?.includes(AppRoles.VS_SPECIALIST) ?? false))
  );
};

export function userHasAppRoles(user) {
  if (!user.approle || user.approle.length == 0) {
    return false;
  }
  return true;
}

// customer roles

export const isApManager = (user: any) => {
  return user.approle?.includes(AppRoles.AP_MANAGER) ?? false;
};

export const isApAdmin = (user: any) => {
  return user.approle?.includes(AppRoles.AP_ADMIN) ?? false;
};

export const isApSpecialist = (user: any) => {
  return user.approle?.includes(AppRoles.AP_SPECIALIST) ?? false;
};

export const isFinancialAccounting = (user: any) => {
  return user.approle?.includes(AppRoles.FINANCIAL_ACCOUNTING) ?? false;
};

export const isUserAdmin = (user: any) => {
  return user.approle?.includes(AppRoles.USER_ADMIN) ?? false;
};

// helpers

export const userRolesIncludesRole = (user: any, rolesRequired: string[]) => {
  return (
    rolesRequired.some((role: string) => user.approle?.includes(role)) ?? false
  );
};

// environment properties and special/custom permissions based on environment
export const isProductionEnv = () => {
  return process.env.VUE_APP_ENVIRONMENT === "production";
};

export const isLocalDevelopment = () => {
  const env = process.env.VUE_APP_ENVIRONMENT ?? "";
  const deployedEnvironments = ["dev", "qa", "stage", "sandbox", "production"];
  return deployedEnvironments.includes(env) === false;
};

export const isCustomerFacingEnv = () => {
  return (
    process.env.VUE_APP_ENVIRONMENT === "production" ||
    process.env.VUE_APP_ENVIRONMENT === "sandbox"
  );
};

export const shouldMakeDataRequest = () => {
  if (
    isCustomerFacingEnv() &&
    isAscendUser(store.getters["UserStore/getUser"]) &&
    store.getters["UserStore/getSelectedCustomer"].id === null
  ) {
    return false;
  }
  return true;
};

export const customPermissions: {
  [key: string]: Function;
} = {
  isInternalDemoAccount: () => {
    return (
      isCustomerFacingEnv() &&
      (store.getters["UserStore/getSelectedCustomer"].id === 10301 ||
        store.getters["UserStore/getSelectedCustomer"].id === 10318) // internal references for sales and internal partner demo tenants
    );
  },
  isInternalUser: () => isAscendUser(store.getters["UserStore/getUser"]),
  shouldShowNotificationCenter: () => {
    return app.$launchDarkly.variation("show-notification-center", false);
  },
  shouldShowUserManagement: () => {
    return (
      (isCustomerFacingEnv() || isLocalDevelopment()) &&
      (isSuperAdmin(store.getters["UserStore/getUser"]) ||
        isUserAdmin(store.getters["UserStore/getUser"]))
    );
  },
  shouldShowTenantManagement: () => {
    return (
      (isCustomerFacingEnv() || isLocalDevelopment()) &&
      isSuperAdmin(store.getters["UserStore/getUser"])
    );
  },
  shouldShowAdvancedSearch: () => {
    const flags = store.getters["UserStore/getFeatureFlagSet"];
    return flags
      ? flags["show-advanced-search"] ?? false
      : app.$launchDarkly.variation("show-advanced-search", false);
  },
  shouldShowFieldSettings: () => {
    const flags = store.getters["UserStore/getFeatureFlagSet"];
    return flags
      ? flags["show-field-settings"] ?? false
      : app.$launchDarkly.variation("show-field-settings", false);
  },
  shouldShowLicensingAnalytics: () => {
    const flags = store.getters["UserStore/getFeatureFlagSet"];
    return flags
      ? flags["should-show-elevateap-licensing"] ?? false
      : app.$launchDarkly.variation("should-show-elevateap-licensing", false);
  },
  shouldShowPayments: () => {
    const flags = store.getters["UserStore/getFeatureFlagSet"];
    return flags
      ? flags["should-show-payments"] ?? false
      : app.$launchDarkly.variation("should-show-payments", false);
  },
  hasEnterpriseProductSubscription: () => {
    const flags = store.getters["UserStore/getFeatureFlagSet"];
    return flags
      ? flags["has-enterprise-subscription"] ?? false
      : app.$launchDarkly.variation("has-enterprise-subscription", false);
  },
  isActiveOnPayments: () => {
    return store.getters["UserStore/getIsPaymentsCustomer"] ?? false;
  },
  isNotActiveOnPayments: () => {
    return store.getters["UserStore/getIsPaymentsCustomer"] === false;
  }
};

import { ChatMessage } from "./chat-message";

export class ChatTransaction {
  public id: number;
  public to: string;
  public messages: ChatMessage[];
  public hasError: boolean;

  constructor(
    id: number,
    to: string,
    messages: ChatMessage[],
    hasError: boolean
  ) {
    this.id = id;
    this.to = to;
    this.messages = messages;
    this.hasError = hasError;
  }
}

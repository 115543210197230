import { RouteConfig } from "vue-router";
import AppRoles from "@/shared/constants/application-roles";

const validationServicesMetadata = {
  title: "Validation Services",
  requiresAuth: true,
  authorize: [AppRoles.VS_MANAGER, AppRoles.VS_SPECIALIST]
};

export const validationServicesRoutes: Array<RouteConfig> = [
  {
    path: "/validation-services",
    name: "Validation Services Landing Page",
    component: () =>
      import(
        /* webpackChunkName: "ValidationServicesLandingPage" */ "@/views/validation-services/ValidationServicesLandingPage.vue"
      ),
    meta: validationServicesMetadata
  },
  {
    path: "/validation-services/:id",
    name: "Validation Services",
    component: () =>
      import(
        /* webpackChunkName: "ValidationServicesCustomerView" */ "@/views/validation-services/ValidationServicesCustomerInvoices.vue"
      ),
    meta: validationServicesMetadata
  }
];

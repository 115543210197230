// customer user roles
const FINANCIAL_ACCOUNTING = "Financial Accounting";
const AP_SPECIALIST = "AP Specialist";
const AP_MANAGER = "AP Manager";
const AP_ADMIN = "AP Admin";
const USER_ADMIN = "User Admin";

// customer user roles for Payments
const PAYMENTS_SPECIALIST = "Payments Specialist";

// internal user roles/groups
const ASCEND_USER = "Ascend Software";
const ASCEND_ADMIN = "Ascend Admin";
const VS_MANAGER = "VS Manager";
const VS_SPECIALIST = "VS Specialist";
const DEMO = "Demo";

export default {
  FINANCIAL_ACCOUNTING,
  AP_SPECIALIST,
  AP_MANAGER,
  AP_ADMIN,
  USER_ADMIN,
  PAYMENTS_SPECIALIST,
  VS_MANAGER,
  VS_SPECIALIST,
  ASCEND_USER,
  ASCEND_ADMIN,
  DEMO
};

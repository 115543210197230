import { InvoiceHeaderFieldsResponse } from "../external-services/raven-api-invoice-header-fields-response";
import { ValidatableField } from "./validatable-field";
import {
  DocumentRuleAutocompleteInput,
  DocumentRuleSelectInput,
  DocumentRuleTextInput
} from "./document-rule-inputs";
import { RADIO_OPTIONS } from "@/shared/constants/document-rule";

export default class DocumentRuleHeaderFields {
  // initial settings
  public supplier: DocumentRuleAutocompleteInput;
  public companytype: string;
  public company: DocumentRuleAutocompleteInput;
  public customeraccttype: string;
  public customeracct: DocumentRuleTextInput;
  // other header fields
  public remitto: DocumentRuleAutocompleteInput;
  public currency: DocumentRuleAutocompleteInput;
  public paymentterms: DocumentRuleAutocompleteInput;
  public paymenttype: DocumentRuleAutocompleteInput;
  public shipto: DocumentRuleAutocompleteInput;
  public taxoption: DocumentRuleSelectInput;
  public memo: DocumentRuleTextInput;
  public approver: DocumentRuleAutocompleteInput;
  public requester: DocumentRuleAutocompleteInput;
  public prepaidtype: DocumentRuleSelectInput;
  public handlingcode: DocumentRuleAutocompleteInput;

  constructor() {
    this.supplier = new DocumentRuleAutocompleteInput();
    this.companytype = RADIO_OPTIONS.ALL;
    this.company = new DocumentRuleAutocompleteInput(RADIO_OPTIONS.ALL);
    this.customeraccttype = RADIO_OPTIONS.ALL;
    this.customeracct = new DocumentRuleTextInput(RADIO_OPTIONS.ALL);
    this.remitto = new DocumentRuleAutocompleteInput();
    this.currency = new DocumentRuleAutocompleteInput();
    this.paymentterms = new DocumentRuleAutocompleteInput();
    this.paymenttype = new DocumentRuleAutocompleteInput();
    this.shipto = new DocumentRuleAutocompleteInput();
    this.taxoption = new DocumentRuleSelectInput(
      { text: "Select One", value: 0 },
      [
        {
          text: "Select One",
          value: 0
        },
        {
          text: "Calculate Self Assessed Tax",
          value: 1
        },
        {
          text: "Calculate Tax Due to Supplier",
          value: 2
        },
        {
          text: "Enter Tax Due to Supplier",
          value: 3
        }
      ]
    );
    this.memo = new DocumentRuleTextInput();
    this.approver = new DocumentRuleAutocompleteInput();
    this.requester = new DocumentRuleAutocompleteInput();
    this.prepaidtype = new DocumentRuleSelectInput();
    this.handlingcode = new DocumentRuleAutocompleteInput();
  }

  // map raven-api invoice fields response to document rule settings
  setExistingValuesForEditMode(
    fields: InvoiceHeaderFieldsResponse[],
    includeInitialSettings?: boolean
  ) {
    fields?.forEach((field: InvoiceHeaderFieldsResponse) => {
      const fieldName = field?.name?.toLowerCase();
      const shouldSetField = includeInitialSettings
        ? true
        : !["supplier", "company", "customeracct"].includes(fieldName);

      if (Object.prototype.hasOwnProperty.call(this, fieldName)) {
        // populate autocomplete inputs (exclude initial settings)
        if (this[fieldName].inputType === "autocomplete" && shouldSetField) {
          const initialOption = {
            id: field.value ?? "",
            description: field.text ?? "",
            displayValue: field.text ?? ""
          };
          this[fieldName].options =
            (field.text?.length ?? 0) > 0 ? [initialOption] : [];
          this[fieldName].value =
            (field.value?.length ?? 0) > 0 ? field.value : null;
          this[fieldName].placeholder = field.placeholder ?? "";
        }
        // populate options for prepaidtype
        if (fieldName === "prepaidtype") {
          field.options?.forEach(option => {
            this[fieldName].options?.push({
              text: option["prepayment_Release_Type_Name"],
              value: option["prepayment_Release_Type_ID"]
            });
          });
        }
        // populate select input
        if (this[fieldName].inputType === "select") {
          this[fieldName].value = this[fieldName].options.find(
            option => option.value == field.value
          );
        }
        // populate text input
        if (this[fieldName].inputType === "text") {
          this[fieldName].placeholder = field.placeholder ?? "";
          this[fieldName].value = field.value ?? "";
        }
        // set validation state for all inputs
        this[fieldName].isValid = true;
        this[fieldName].hasError = false;
      }

      if (includeInitialSettings) {
        this["companytype"] =
          (this.company.value as string) === RADIO_OPTIONS.ALL
            ? RADIO_OPTIONS.ALL
            : RADIO_OPTIONS.ONE;

        this["customeraccttype"] =
          (this.customeracct.value as string) === RADIO_OPTIONS.ALL
            ? RADIO_OPTIONS.ALL
            : RADIO_OPTIONS.ONE;
      }
    });
    return this;
  }

  setSupplierFromDocumentEntry(supplier: ValidatableField) {
    this.supplier = Object.assign({}, this.supplier, {
      ...this.supplier,
      value: supplier.value as string,
      options: supplier.options ?? [],
      isValid: supplier.isValid,
      hasError: supplier.hasError,
      errorMessage: supplier.errorMessage ?? ""
    });
  }

  setCompanyFromDocumentEntry(company: ValidatableField) {
    this.companytype =
      ((company.value as string)?.length ?? 0) > 0
        ? RADIO_OPTIONS.ONE
        : RADIO_OPTIONS.ALL;

    const newCompany = {
      ...this.company,
      search: company.search ?? company.value,
      options: company.options,
      isValid: company.isValid,
      hasError: company.hasError,
      errorMessage: company.errorMessage ?? "",
      value:
        ((company.value as string)?.length ?? 0) > 0
          ? (company.value as string) ?? ""
          : RADIO_OPTIONS.ALL
    };

    this.company = Object.assign({}, this.company, newCompany);
  }

  setCustomerAcctFromDocumentEntry(customeracct: ValidatableField) {
    this.customeraccttype =
      ((customeracct.value as string)?.length ?? 0) > 0
        ? RADIO_OPTIONS.ONE
        : RADIO_OPTIONS.ALL;

    this.customeracct = Object.assign({}, this.customeracct, {
      ...this.customeracct,
      value:
        ((customeracct.value as string)?.length ?? 0) > 0
          ? (customeracct.value as string) ?? ""
          : RADIO_OPTIONS.ALL,
      isValid: customeracct.isValid,
      hasError: customeracct.hasError,
      errorMessage: customeracct.errorMessage ?? ""
    });
  }
}

import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import NavSidebarStore from "./nav-sidebar.store";
import UserStore from "./user.store";
import DocViewerStore from "./doc-viewer.store";
import RecognitionAnalyticsStore from "./reporting-analytics/recognition-analytics.store";
import RecognitionRateAnalyticsStore from "./reporting-analytics/recognition-rate.store";
import InvoiceVolumeAnalyticsStore from "./reporting-analytics/invoice-volume.store";
import InvoicesAnalyticsStore from "./reporting-analytics/invoices.store";
import AutomationAnalyticsStore from "./reporting-analytics/automation.store";
import InvoiceListStore from "./invoice-list.store";
import DocumentRulesStore from "./document-rules.store";
import DocumentCommentsStore from "./document-comments.store";
import AdvancedSearchStore from "./advanced-search.store";
import PaymentActivityStore from "./payments/payment-activity.store";
import SettlementRunActivityStore from "./payments/settlement-run-activity.store";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    AdvancedSearchStore,
    NavSidebarStore,
    UserStore,
    DocViewerStore,
    InvoiceListStore,
    RecognitionAnalyticsStore,
    RecognitionRateAnalyticsStore,
    InvoiceVolumeAnalyticsStore,
    InvoicesAnalyticsStore,
    AutomationAnalyticsStore,
    DocumentRulesStore,
    DocumentCommentsStore,
    PaymentActivityStore,
    SettlementRunActivityStore
  },
  plugins: [
    createPersistedState({
      // persist specific states between page refreshes and tabs
      paths: [
        "UserStore.user",
        "UserStore.idToken",
        "UserStore.accessToken",
        "UserStore.o4oToken",
        "UserStore.selectedCustomer",
        "UserStore.featureFlagSet",
        "InvoiceListStore.filters",
        "InvoiceListStore.tabFilter",
        "InvoiceListStore.invoicesListPathObject"
      ]
    })
  ]
});

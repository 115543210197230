import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#08445B",
        accent: "#D26856",
        background: "#E5E5E5",
        error: "#FB2424",
        success: "#CEF0CE",
        alternatesuccess: "#BFD9BA",
        info: "#1195BF",
        text: "#213A49"
      },
      dark: {
        primary: "#2f2b2b",
        accent: "#D26856",
        background: "#1b1919",
        error: "#FB2424",
        success: "#CEF0CE",
        alternatesuccess: "#BFD9BA",
        info: "#1195BF",
        text: "#FFFFFF"
      }
    }
  }
});

"use strict";

import Vue from "vue";
import https from "https";
import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { app } from "@/main";
import {
  checkCurrentRouteAndRedirect,
  handleBadRequest
} from "@/helpers/router-helpers";

const deployments = ["dev", "qa", "stage", "sandbox", "production"];

const config = {
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true,
  httpsAgent: new https.Agent({
    rejectUnauthorized: false
  })
};

const _axios = axios.create(config);

_axios.defaults.withCredentials = true;

_axios.interceptors.request.use(
  // Do something before request is sent
  (config: AxiosRequestConfig) => {
    // eslint-disable-next-line
    if (deployments.includes(process.env.VUE_APP_ENVIRONMENT!)) {
      // add customer aws region to URL base path
      const region = app.$store.getters["UserStore/getSelectedCustomer"].region
        ? app.$store.getters["UserStore/getSelectedCustomer"].region
        : process.env.VUE_APP_REGION;
      config.baseURL =
        config.baseURL?.substring(0, config.baseURL.lastIndexOf("/") + 1) +
        region +
        "." +
        config.baseURL?.substring(config.baseURL.lastIndexOf("/") + 1);
    }
    // Attach authorization and customerid headers
    config.headers.common[
      "Authorization"
    ] = `Bearer ${app.$auth.getAccessToken() ??
      app.$store.getters["UserStore/getAccessToken"]}`;
    config.headers["customerid"] =
      app.$store.getters["UserStore/getSelectedCustomer"].id;
    config.headers["o4otoken"] = app.$store.getters["UserStore/getO4oToken"];
    return config;
  },
  // Do something with request error
  (error: any) => Promise.reject(error)
);

// Add a response interceptor
_axios.interceptors.response.use(
  // Do something with response data
  (response: any) => {
    if (
      response &&
      response.data &&
      response.data.statusCode &&
      response.data.data
    ) {
      if (response.data.statusCode != 200) {
        switch (response.data.statusCode) {
          case 401:
            checkCurrentRouteAndRedirect(app.$router, "/403/access");
            break;
          case 403:
            checkCurrentRouteAndRedirect(app.$router, "/403/access");
            break;
          default:
            throw new Error(response.data.errorMessage);
        }
      }
      return response.data.data;
    }
    return response;
  },
  // Do something with response error
  (error: any) => {
    if (
      error.config &&
      Object.prototype.hasOwnProperty.call(error.config, "errorHandle") &&
      error.config?.errorHandle === false
    ) {
      // request specified to not use error handle functions
      return Promise.reject(error);
    } else if (error.response) {
      // client received an error response (5xx, 4xx)
      switch (error.response.status) {
        case 401:
          checkCurrentRouteAndRedirect(app.$router, "/403/access");
          break;
        case 403:
          checkCurrentRouteAndRedirect(app.$router, "/403/access");
          break;
        default:
          // handle 400 BadRequest calls individually
          return handleBadRequest(error);
      }
    } else if (error.request) {
      // client never received a response, or request never left
      return Promise.reject(error);
    } else {
      // anything else
      return Promise.reject(error);
    }
  }
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function RavenAPIPlugin(vue: typeof Vue, options?: any): void {
  vue.prototype.$ravenapi = _axios;
}

declare module "vue/types/vue" {
  interface Vue {
    $ravenapi: AxiosInstance;
  }
}

export default RavenAPIPlugin;

import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import moment from "moment";
import { CategoryChartData } from "@/models/reporting-analytics/category-chart-data";

@Module({ name: "AutomationAnalyticsStore", namespaced: true })
class AutomationAnalyticsStore extends VuexModule {
  private dateRange: { [key: string]: string } = {
    startDate: moment()
      .subtract(1, "weeks")
      .format()
      .toString()
      .substring(0, 10),
    endDate: moment()
      .format()
      .toString()
      .substring(0, 10)
  };
  private searchButtonClicked = 0;
  private invoiceVolumeByProcessorData: CategoryChartData[] = [];
  private numOfApProcessors = 0;
  private autoAddDataSnapshot = "0 of 0";
  private autoAddSetting = "N/A";
  private autoAddInvoiceData: CategoryChartData[] = [];
  private autoAddSampleSizeData: CategoryChartData[] = [];
  private touchlessProcessingDataSnapshot = "0 of 0";

  @Mutation
  private setDateRange(dateRange: { [key: string]: string }) {
    this.dateRange = dateRange;
  }

  @Mutation
  private setSearchButtonClicked() {
    this.searchButtonClicked += 1;
  }

  @Mutation
  private resetState() {
    this.searchButtonClicked = 0;
    this.searchButtonClicked = 0;
    this.invoiceVolumeByProcessorData = [];
    this.numOfApProcessors = 0;
    this.autoAddDataSnapshot = "0 of 0";
    this.autoAddSetting = "N/A";
    this.autoAddInvoiceData = [];
    this.autoAddSampleSizeData = [];
    this.touchlessProcessingDataSnapshot = "0 of 0";
  }

  @Mutation
  private setInvoiceVolumeByProcessorData(data: CategoryChartData[]) {
    this.invoiceVolumeByProcessorData = data;
  }

  @Mutation
  private setNumberOfApProcessors(numOfApProcessors: number) {
    this.numOfApProcessors = numOfApProcessors;
  }

  @Mutation
  private setAutoAddDataSnapshot(data: string) {
    this.autoAddDataSnapshot = data;
  }

  @Mutation
  private setAutoAddInvoiceData(data: CategoryChartData[]) {
    this.autoAddInvoiceData = data;
  }

  @Mutation
  private setAutoAddSampleSizeData(data: CategoryChartData[]) {
    this.autoAddSampleSizeData = data;
  }

  @Mutation
  private setAutoAddSetting(setting: string) {
    this.autoAddSetting = setting;
  }

  @Mutation
  private setTouchlessProcessingDataSnapshot(data: string) {
    this.touchlessProcessingDataSnapshot = data;
  }

  @Action({ rawError: true })
  public updateDateRange(dateRange: { [key: string]: string }) {
    this.context.commit("setDateRange", dateRange);
  }

  @Action({ rawError: true })
  public clickSearchButton() {
    this.context.commit("setSearchButtonClicked");
  }

  @Action({ rawError: true })
  public updateInvoiceVolumeByProcessorData(data: CategoryChartData[]) {
    this.context.commit("setInvoiceVolumeByProcessorData", data);
  }

  @Action({ rawError: true })
  public updateNumberOfApProcessors(numOfApProcessors: number) {
    this.context.commit("setNumberOfApProcessors", numOfApProcessors);
  }

  @Action({ rawError: true })
  public updateAutoAddDataSnapshot(data: string) {
    this.context.commit("setAutoAddDataSnapshot", data);
  }

  @Action({ rawError: true })
  public updateAutoAddInvoiceData(data: CategoryChartData[]) {
    this.context.commit("setAutoAddInvoiceData", data);
  }

  @Action({ rawError: true })
  public updateAutoAddSampleSizeData(data: CategoryChartData[]) {
    this.context.commit("setAutoAddSampleSizeData", data);
  }

  @Action({ rawError: true })
  public updateAutoAddSetting(setting: string) {
    this.context.commit("setAutoAddSetting", setting);
  }

  @Action({ rawError: true })
  public updateTouchlessProcessingDataSnapshot(data: string) {
    this.context.commit("setTouchlessProcessingDataSnapshot", data);
  }

  @Action({ rawError: true })
  public resetToInitialState() {
    this.context.commit("resetState");
  }

  public get getDateRange(): { [key: string]: string } {
    return this.dateRange;
  }

  public get getSearchButtonClicked(): number {
    return this.searchButtonClicked;
  }

  public get getInvoiceVolumeByProcessorData(): CategoryChartData[] {
    return this.invoiceVolumeByProcessorData;
  }

  public get getNumOfApProcessors(): number {
    return this.numOfApProcessors;
  }

  public get getAutoAddDataSnapshot(): string {
    return this.autoAddDataSnapshot;
  }

  public get getAutoAddInvoiceData(): CategoryChartData[] {
    return this.autoAddInvoiceData;
  }

  public get getAutoAddSampleSizeData(): CategoryChartData[] {
    return this.autoAddSampleSizeData;
  }

  public get getAutoAddSetting(): string {
    return this.autoAddSetting;
  }

  public get getTouchlessProcessingDataSnapshot(): string {
    return this.touchlessProcessingDataSnapshot;
  }
}

export default AutomationAnalyticsStore;

import { app } from "@/main";
import { CustomerSummaryItem } from "@/models/validation-services/customers-list";
import { checkCurrentRouteAndRedirect } from "@/helpers/router-helpers";

class ValidationServicesService {
  public async getInvoiceList(clientID: string) {
    return await app.$ravenapi.get(`api/invoices/active/vs`);
  }

  public async getCustomersWithVSInvoices() {
    const response = await app.$ravenapi.get(
      `/api/validationservices/customers`
    );
    return (
      (response as any).customerSummaries?.map(
        (customerSum: CustomerSummaryItem) => ({
          id: customerSum.id,
          name: customerSum.name,
          totalInvoices: customerSum.totalInvoices,
          expires: customerSum.expires,
          region: customerSum.region
            ? customerSum.region
            : process.env.VUE_APP_REGION
        })
      ) ?? []
    );
  }
}

export const validationServicesService = new ValidationServicesService(); // singleton

import AppRoles from "@/shared/constants/application-roles";

export default [
  {
    title: "Payments",
    icon: "mdi-wallet",
    href: "/payments/activity",
    group: "payments",
    authorize: [AppRoles.ASCEND_ADMIN, AppRoles.PAYMENTS_SPECIALIST],
    internalGroups: ["shouldShowPayments", "isActiveOnPayments"],
    subGroup: [
      {
        title: "Payments",
        href: "/payments/activity",
        authorize: [AppRoles.ASCEND_ADMIN, AppRoles.PAYMENTS_SPECIALIST]
      },
      {
        title: "Settlement Runs",
        href: "/payments/settlements",
        authorize: [AppRoles.ASCEND_ADMIN, AppRoles.PAYMENTS_SPECIALIST]
      }
    ]
  }
];

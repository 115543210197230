const DEFAULT_OPTIONS = {
  duration: 10000,
  theme: "toasted-primary",
  fullWidth: true,
  action: {
    text: "",
    icon: "mdi-close",
    onClick: (e, toastObject) => {
      toastObject.goAway(0);
    }
  }
};

const ERROR_OPTIONS = {
  ...DEFAULT_OPTIONS,
  type: "error",
  icon: "mdi-alert-circle-outline"
};

const SUCCESS_OPTIONS = {
  ...DEFAULT_OPTIONS,
  type: "success",
  icon: "mdi-check-circle-outline"
};

const INFO_OPTIONS = {
  ...DEFAULT_OPTIONS,
  type: "info",
  icon: "mdi-alert-circle-outline"
};

export default {
  ERROR_OPTIONS,
  SUCCESS_OPTIONS,
  INFO_OPTIONS
};

import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import moment from "moment";
import { InvoiceListData } from "@/models/reporting-analytics/invoice-list-data";

@Module({ name: "InvoicesAnalyticsStore", namespaced: true })
class InvoicesAnalyticsStore extends VuexModule {
  private dateRange: { [key: string]: string } = {
    startDate: moment()
      .subtract(1, "weeks")
      .format()
      .toString()
      .substring(0, 10),
    endDate: moment()
      .format()
      .toString()
      .substring(0, 10)
  };
  private invoiceListData: InvoiceListData[] = [];
  private searchButtonClicked = 0;
  private selectedInvoice: null | InvoiceListData = null;
  private selectedInvoicePdfLocation = "";

  @Mutation
  private setDateRange(dateRange: { [key: string]: string }) {
    this.dateRange = dateRange;
  }

  @Mutation
  private setInvoiceListData(data: InvoiceListData[]) {
    this.invoiceListData = data;
  }

  @Mutation
  private setSearchButtonClicked() {
    this.searchButtonClicked += 1;
  }

  @Mutation
  private resetState() {
    this.invoiceListData = [];
    this.searchButtonClicked = 0;
    this.selectedInvoice = null;
    this.selectedInvoicePdfLocation = "";
  }

  @Mutation
  private setSelectedInvoice(invoice: InvoiceListData | null) {
    this.selectedInvoice = invoice;
    if (invoice === null) {
      this.selectedInvoicePdfLocation = "";
    }
  }

  @Mutation
  private setSelectedInvoicePdfLocation(location: ArrayBuffer | string) {
    if (typeof location !== "string") {
      // convert ArrayBuffer to Blob
      const bytes = new Uint8Array(location);
      const blob = new Blob([bytes], { type: "application/pdf" });
      // create data URL from Blob
      this.selectedInvoicePdfLocation = window.URL.createObjectURL(blob);
    } else {
      this.selectedInvoicePdfLocation = location;
    }
  }

  @Action({ rawError: true })
  public updateDateRange(dateRange: { [key: string]: string }) {
    this.context.commit("setDateRange", dateRange);
  }

  @Action({ rawError: true })
  public updateInvoiceListData(data: InvoiceListData[]) {
    this.context.commit("setInvoiceListData", data);
  }

  @Action({ rawError: true })
  public clickSearchButton() {
    this.context.commit("setSearchButtonClicked");
  }

  @Action({ rawError: true })
  public updateSelectedInvoice(invoice: InvoiceListData | null) {
    this.context.commit("setSelectedInvoice", invoice);
  }

  @Action({ rawError: true })
  public updateSelectedInvoicePdfLocation(location: ArrayBuffer | string) {
    this.context.commit("setSelectedInvoicePdfLocation", location);
  }

  @Action({ rawError: true })
  public resetToInitialState() {
    this.context.commit("resetState");
  }

  public get getDateRange(): { [key: string]: string } {
    return this.dateRange;
  }

  public get getInvoiceListData(): InvoiceListData[] {
    return this.invoiceListData;
  }

  public get getSearchButtonClicked(): number {
    return this.searchButtonClicked;
  }

  public get getSelectedInvoice(): null | InvoiceListData {
    return this.selectedInvoice;
  }

  public get getSelectedInvoicePdfLocation(): string {
    return this.selectedInvoicePdfLocation;
  }
}

export default InvoicesAnalyticsStore;

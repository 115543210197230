import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import RavenAPIPlugin from "./plugins/raven-api";
import LaunchDarklyPlugin from "./plugins/launch-darkly";
import vuetify from "./plugins/vuetify";
import DefaultLayout from "./components/layouts/Default.vue";
import MinimalLayout from "./components/layouts/Minimal.vue";
import ErrorLayout from "./components/layouts/Error.vue";
import Toasted from "vue-toasted";
import options from "@/shared/constants/toast-options";
import * as Sentry from "@sentry/vue";
import { Integrations as TracingIntegrations } from "@sentry/tracing";
import "./registerServiceWorker";

// globally import layout components
Vue.component("default-layout", DefaultLayout);
Vue.component("minimal-layout", MinimalLayout);
Vue.component("error-layout", ErrorLayout);

Vue.config.productionTip = false;

// initialize Sentry in deployment environments
if (process.env.NODE_ENV === "production") {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    environment: process.env.VUE_APP_ENVIRONMENT,
    trackComponents: true,
    attachProps: true,
    attachStacktrace: true,
    logErrors: true,
    integrations: [
      new TracingIntegrations.BrowserTracing({
        /* eslint-disable @typescript-eslint/no-non-null-assertion */
        tracingOrigins: [
          process.env.VUE_APP_API_URL!.replace(/^https?:\/\//, ""),
          /^\//
        ]
        /* eslint-enable @typescript-eslint/no-non-null-assertion */
      })
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // Sentry recommends adjusting this value in production
    tracesSampleRate: 0.05
  });
}

// use custom plugins
Vue.use(RavenAPIPlugin);
Vue.use(LaunchDarklyPlugin);

// use external toast plugin
Vue.use(Toasted, { iconPack: "mdi" });

export const app = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
});

// global error handler for javascript errors
window.onerror = function(
  message: any,
  source: any,
  line: any,
  column: any,
  error: any
) {
  const string = message.toLowerCase();
  const substring = "script error";
  if (string.indexOf(substring) > -1) {
    app.$toasted.show("<p>Script Error: See browser console for details.</p>", {
      ...options.ERROR_OPTIONS
    });
  } else {
    const displayMessage = [
      "Message: " + message,
      "URL: " + source,
      "Line: " + line,
      "Column: " + column,
      "Error: " + JSON.stringify(error)
    ].join(" - ");

    app.$toasted.show(`<p>${displayMessage}</p>`, {
      ...options.ERROR_OPTIONS
    });
  }
  return false;
};

// global error handler for unhandled promise rejections
window.addEventListener("unhandledrejection", function(event) {
  const promise = event.promise;
  const reason = event.reason;
  app.$toasted.show(`<p>${promise}: ${reason}</p>`, {
    ...options.ERROR_OPTIONS
  });
});

// global error handler for Vue errors
// eslint-disable-next-line @typescript-eslint/no-unused-vars
Vue.config.errorHandler = function(err: any, vm: any, info: any) {
  app.$toasted.show(`<p>${err.toString()}\nInfo: ${info}</p>`, {
    ...options.ERROR_OPTIONS
  });
};

app.$mount("#app");





















import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class IconButton extends Vue {
  @Prop() tooltipText!: string;
  @Prop() materialIconToDisplay!: string;
  @Prop() handleClick!: Function;
  @Prop({ default: "" }) btnClass?: string;
  @Prop() tooltipColor?: string;
  @Prop({ default: false }) isReadOnly?: boolean;

  private get isButtonInactive(): boolean {
    if (this.isReadOnly == undefined) {
      return false;
    }
    return this.isReadOnly;
  }
}

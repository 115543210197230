import { app } from "./main";

const {
  VUE_APP_OKTA_ISSUER,
  VUE_APP_OKTA_CLIENT_ID,
  NODE_ENV,
  VUE_APP_API_URL
} = process.env;

export default {
  oidc: {
    clientId: VUE_APP_OKTA_CLIENT_ID,
    issuer: VUE_APP_OKTA_ISSUER,
    redirectUri: window.location.origin + "/login/callback",
    scopes: ["openid", "profile", "email", "offline_access", "dac.admin"],
    pkce: true,
    tokenManager: {
      autoRenew: true
    },
    testing: {
      disableHttpsCheck: NODE_ENV === "production" ? false : true
    },
    onSessionExpired: async () => {
      await app.$auth.closeSession();
      await app.$auth.signOut({
        postLogoutRedirectUri: window.location.origin + "/logout"
      });
    }
  },
  api: VUE_APP_API_URL
};

import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { LDFlagChangeset } from "launchdarkly-js-client-sdk";
import { Customer } from "@/models/user/customer";

@Module({ name: "UserStore", namespaced: true })
class UserStore extends VuexModule {
  private authenticated = false;
  private user: any | null = null;
  private idToken = "";
  private accessToken = "";
  private o4oToken = ""; // used to communicate with Okta API
  private selectedCustomer: Customer = {
    id: null,
    description: "",
    displayValue: "",
    region: ""
  };
  private featureFlagSet: LDFlagChangeset | null = null;
  private apiIsHealthy: boolean | null = null;
  private isPaymentsCustomer = false;
  private userBrowserLocale: string = navigator.language;

  @Mutation
  private setAuthenticationState(isAuthenticated: boolean) {
    this.authenticated = isAuthenticated;
  }

  @Mutation
  private setUserMutation(user: any | null) {
    this.user = user;
  }

  @Mutation
  private setIdTokenMutation(idToken: string) {
    this.idToken = idToken;
  }

  @Mutation
  private setAccessTokenMutation(accessToken: string) {
    this.accessToken = accessToken;
  }

  @Mutation
  private setO4oTokenMutation(o4oToken: string) {
    this.o4oToken = o4oToken;
  }

  @Mutation
  private setSelectedCustomerMutation(selectedCustomer: Customer) {
    this.selectedCustomer = selectedCustomer;
  }

  @Mutation
  private setFeatureFlagSetMutation(flags: LDFlagChangeset) {
    this.featureFlagSet = flags;
  }

  @Mutation
  private setApiIsHealthyMutation(isHealthy: boolean) {
    this.apiIsHealthy = isHealthy;
  }

  @Mutation
  private setIsPaymentsCustomerMutation(isActive: boolean) {
    this.isPaymentsCustomer = isActive;
  }

  @Mutation
  private setUserBrowserLocaleMutation(locale: string) {
    this.userBrowserLocale = locale;
  }

  @Action
  public updateAuthStatus(isAuthenticated: boolean) {
    this.context.commit("setAuthenticationState", isAuthenticated);
  }

  @Action
  public setUser(user: any | null) {
    this.context.commit("setUserMutation", user);
  }

  @Action
  public setIdToken(idToken: string) {
    this.context.commit("setIdTokenMutation", idToken);
  }

  @Action
  public setAccessToken(accessToken: string) {
    this.context.commit("setAccessTokenMutation", accessToken);
  }

  @Action
  public setO4oToken(o4oToken: string) {
    this.context.commit("setO4oTokenMutation", o4oToken);
  }

  @Action
  public setSelectedCustomer(selectedCustomer: Customer) {
    this.context.commit("setSelectedCustomerMutation", selectedCustomer);
  }

  @Action
  public setFeatureFlagSet(flags: LDFlagChangeset) {
    this.context.commit("setFeatureFlagSetMutation", flags);
  }

  @Action
  public setApiIsHealthy(isHealthy: boolean) {
    this.context.commit("setApiIsHealthyMutation", isHealthy);
  }

  @Action
  public setIsPaymentsCustomer(isActive: boolean) {
    this.context.commit("setIsPaymentsCustomerMutation", isActive);
  }

  @Action setUserBrowserLocale(locale: string) {
    this.context.commit("setUserBrowserLocaleMutation", locale);
  }

  public get getAuthenticationState(): boolean {
    return this.authenticated;
  }

  public get getUser(): any | null {
    return this.user;
  }

  public get getIdToken(): string {
    return this.idToken;
  }

  public get getAccessToken(): string {
    return this.accessToken;
  }

  public get getO4oToken(): string {
    return this.o4oToken;
  }

  public get getSelectedCustomer(): Customer {
    return this.selectedCustomer;
  }

  public get getFeatureFlagSet(): LDFlagChangeset | null {
    return this.featureFlagSet;
  }

  public get getApiIsHealthy(): boolean | null {
    return this.apiIsHealthy;
  }

  public get getIsPaymentsCustomer(): boolean {
    return this.isPaymentsCustomer;
  }

  public get getUserBrowserLocale(): string {
    return this.userBrowserLocale;
  }
}

export default UserStore;

import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import moment from "moment";
import { TimelineChartData } from "@/models/reporting-analytics/timeline-data";
import { CategoryChartData } from "@/models/reporting-analytics/category-chart-data";

@Module({ name: "InvoiceVolumeAnalyticsStore", namespaced: true })
class InvoiceVolumeAnalyticsStore extends VuexModule {
  private dateRange: { [key: string]: string } = {
    startDate: moment()
      .subtract(1, "weeks")
      .format()
      .toString()
      .substring(0, 10),
    endDate: moment()
      .format()
      .toString()
      .substring(0, 10)
  };
  private searchButtonClicked = 0;
  private invoiceVolumeByTabData: CategoryChartData[] = [];
  private invoiceVolumeBySupplierData: CategoryChartData[] = [];
  private invoiceCancelledBySupplierData: CategoryChartData[] = [];
  private invoiceVolumeHistoricalData: TimelineChartData[] = [];
  private numOfInvoicesReceived = 0;
  private numOfSuppliers = 0;
  private numOfInvoicesCancelled = 0;
  private numOfInvoiceCategories = 0;

  @Mutation
  private setDateRange(dateRange: { [key: string]: string }) {
    this.dateRange = dateRange;
  }

  @Mutation
  private setSearchButtonClicked() {
    this.searchButtonClicked += 1;
  }

  @Mutation
  private resetState() {
    this.searchButtonClicked = 0;
    this.invoiceVolumeByTabData = [];
    this.invoiceVolumeBySupplierData = [];
    this.invoiceCancelledBySupplierData = [];
    this.invoiceVolumeHistoricalData = [];
    this.numOfInvoicesReceived = 0;
    this.numOfSuppliers = 0;
    this.numOfInvoicesCancelled = 0;
    this.numOfInvoiceCategories = 0;
  }

  @Mutation
  private setInvoiceVolumeByTabData(data: CategoryChartData[]) {
    this.invoiceVolumeByTabData = data;
  }

  @Mutation
  private setInvoiceVolumeBySupplierData(data: CategoryChartData[]) {
    this.invoiceVolumeBySupplierData = data;
  }

  @Mutation
  private setInvoiceCancelledBySupplierData(data: CategoryChartData[]) {
    this.invoiceCancelledBySupplierData = data;
  }

  @Mutation
  private setInvoiceVolumeHistoricalData(data: TimelineChartData[]) {
    this.invoiceVolumeHistoricalData = data;
  }

  @Mutation
  private setNumberOfInvoicesReceived(numOfInvoices: number) {
    this.numOfInvoicesReceived = numOfInvoices;
  }

  @Mutation
  private setNumberOfSuppliers(numOfSuppliers: number) {
    this.numOfSuppliers = numOfSuppliers;
  }

  @Mutation
  private setNumberOfInvoicesCancelled(numOfInvoicesCancelled: number) {
    this.numOfInvoicesCancelled = numOfInvoicesCancelled;
  }

  @Mutation
  private setNumberOfInvoiceCategories(numOfApPortalTabs: number) {
    this.numOfInvoiceCategories = numOfApPortalTabs;
  }

  @Action({ rawError: true })
  public updateDateRange(dateRange: { [key: string]: string }) {
    this.context.commit("setDateRange", dateRange);
  }

  @Action({ rawError: true })
  public clickSearchButton() {
    this.context.commit("setSearchButtonClicked");
  }

  @Action({ rawError: true })
  public updateInvoiceVolumeByTabData(data: CategoryChartData[]) {
    this.context.commit("setInvoiceVolumeByTabData", data);
  }

  @Action({ rawError: true })
  public updateInvoiceVolumeBySupplierData(data: CategoryChartData[]) {
    this.context.commit("setInvoiceVolumeBySupplierData", data);
  }

  @Action({ rawError: true })
  public updateInvoiceCancelledBySupplierData(data: CategoryChartData[]) {
    this.context.commit("setInvoiceCancelledBySupplierData", data);
  }

  @Action({ rawError: true })
  public updateInvoiceVolumeHistoricalData(data: TimelineChartData[]) {
    this.context.commit("setInvoiceVolumeHistoricalData", data);
  }

  @Action({ rawError: true })
  public updateNumberOfSuppliers(numOfSuppliers: number) {
    this.context.commit("setNumberOfSuppliers", numOfSuppliers);
  }

  @Action({ rawError: true })
  public updateNumOfInvoicesCancelled(numOfInvoicesCancelled: number) {
    this.context.commit("setNumberOfInvoicesCancelled", numOfInvoicesCancelled);
  }

  @Action({ rawError: true })
  public updateNumberOfInvoiceCategories(numOfApPortalTabs: number) {
    this.context.commit("setNumberOfInvoiceCategories", numOfApPortalTabs);
  }

  @Action({ rawError: true })
  public updateNumberOfInvoicesReceived(numOfInvoicesReceived: number) {
    this.context.commit("setNumberOfInvoicesReceived", numOfInvoicesReceived);
  }

  @Action({ rawError: true })
  public resetToInitialState() {
    this.context.commit("resetState");
  }

  public get getDateRange(): { [key: string]: string } {
    return this.dateRange;
  }

  public get getSearchButtonClicked(): number {
    return this.searchButtonClicked;
  }

  public get getInvoiceVolumeBySupplierData(): CategoryChartData[] {
    return this.invoiceVolumeBySupplierData;
  }

  public get getInvoiceCancelledBySupplierData(): CategoryChartData[] {
    return this.invoiceCancelledBySupplierData;
  }

  public get getInvoiceVolumeByTabData(): CategoryChartData[] {
    return this.invoiceVolumeByTabData;
  }

  public get getInvoiceVolumeHistoricalData(): TimelineChartData[] {
    return this.invoiceVolumeHistoricalData;
  }

  public get getNumOfSuppliers(): number {
    return this.numOfSuppliers;
  }

  public get getNumOfInvoicesCancelled(): number {
    return this.numOfInvoicesCancelled;
  }

  public get getNumOfInvoicesReceived(): number {
    return this.numOfInvoicesReceived;
  }

  public get getNumOfInvoiceCategories(): number {
    return this.numOfInvoiceCategories;
  }
}

export default InvoiceVolumeAnalyticsStore;















import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class PrimaryButton extends Vue {
  @Prop() buttonText!: string;
  @Prop() handleClick!: Function;
  @Prop({ default: "" }) customClass!: string;
  @Prop({ default: "" }) width?: string;
  @Prop({ default: false }) disabled?: boolean;
}

import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { comment } from "@/models/document-entry/comment";

@Module({ name: "DocumentCommentsStore", namespaced: true })
class DocumentCommentsStore extends VuexModule {
  private isDocumentCommentsPanelDisplayed = false;
  private isDeleteCommentConfirmationModalDisplayed = false;
  private documentComments: comment[] = [];
  private documentCommentSelectedForDelete: null | comment = null;

  @Mutation
  private changeStateOfDocumentCommentsPanelMutation() {
    this.isDocumentCommentsPanelDisplayed = !this
      .isDocumentCommentsPanelDisplayed;
  }

  @Mutation
  private changeStateOfDeleteConfirmationModalMutation() {
    this.isDeleteCommentConfirmationModalDisplayed = !this
      .isDeleteCommentConfirmationModalDisplayed;
  }

  @Mutation
  private setDocumentCommentsMutation(comments: comment[]) {
    this.documentComments = comments;
  }

  @Mutation
  private addDocumentCommentMutation(documentComment: comment) {
    this.documentComments.unshift(documentComment);
  }

  @Mutation
  private deleteDocumentCommentMutation(documentComment: comment) {
    const index: number = this.documentComments.indexOf(documentComment);
    if (index >= 0) {
      this.documentComments.splice(index, 1);
    }
  }

  @Mutation
  private storeCommentToDeleteMutation(documentComment: null | comment) {
    this.documentCommentSelectedForDelete = documentComment;
  }

  @Action
  public changeStateOfDocumentCommentsPanel() {
    this.context.commit("changeStateOfDocumentCommentsPanelMutation");
  }

  @Action
  public changeStateOfDeleteConfirmationModal() {
    this.context.commit("changeStateOfDeleteConfirmationModalMutation");
  }

  @Action
  public setDocumentComments(comments: comment[]) {
    this.context.commit("setDocumentCommentsMutation", comments);
  }

  @Action
  public addDocumentComment(documentComment: comment) {
    this.context.commit("addDocumentCommentMutation", documentComment);
  }

  @Action
  public deleteDocumentComment(documentComment: comment) {
    this.context.commit("deleteDocumentCommentMutation", documentComment);
  }

  @Action
  public storeCommentToDelete(documentComment: null | comment) {
    this.context.commit("storeCommentToDeleteMutation", documentComment);
  }

  public get getIsDocumentCommentsPanelDisplayed(): boolean {
    return this.isDocumentCommentsPanelDisplayed;
  }

  public get getIsDeleteCommentConfirmationModalDisplayed(): boolean {
    return this.isDeleteCommentConfirmationModalDisplayed;
  }

  public get getDocumentComments(): comment[] {
    return this.documentComments;
  }

  public get getDocumentCommentSelectedForDelete(): comment | null {
    return this.documentCommentSelectedForDelete;
  }
}
export default DocumentCommentsStore;

import { Vue } from "vue-property-decorator";
import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import {
  DocumentRuleModalMode,
  DocumentRuleType
} from "@/models/document-entry/document-rules";
import { RavenApiDocumentRuleDataResponse } from "@/models/external-services/raven-api-document-rule-type-response";
import { line } from "@/models/document-entry/line";
import DocumentRuleHeaderFields from "@/models/document-entry/document-rule";
import { ValidatableField } from "@/models/document-entry/validatable-field";
import { InvoiceHeaderFieldsResponse } from "@/models/external-services/raven-api-invoice-header-fields-response";
import {
  DocumentRuleAutocompleteInput,
  DocumentRuleSelectInput,
  DocumentRuleTextInput
} from "@/models/document-entry/document-rule-inputs";
import { InputObject } from "@/models/document-entry/input-object";

@Module({ name: "DocumentRulesStore", namespaced: true })
class DocumentRulesStore extends VuexModule {
  private documentRuleUsages: any[] = [];
  private supplierSelectedOnDocumentEntry = {};
  private companySelectedOnDocumentEntry = {};
  private customerAcctSelectedOnDocumentEntry = {};
  private documentRuleID: number | null = null; // this is the prototype-temp record in db
  private prototypeDocumentRuleID: number | null = null; // this is the prototype record in db
  private isNewDocumentRule = true;
  private documentRuleModalMode = DocumentRuleModalMode.CREATE;
  private documentRuleLines: line[] = [];
  private documentRuleHeaderFields: DocumentRuleHeaderFields = new DocumentRuleHeaderFields();
  private isDocumentRuleComplete: { [key: string]: boolean } = {
    one: false,
    two: false,
    three: false
  };
  private openedModalPanels: number[] = [0];
  private documentRuleType: InputObject = new InputObject(
    "No Automation",
    DocumentRuleType.NO_AUTOMATION
  );
  private documentRulesListForSupplier: RavenApiDocumentRuleDataResponse[] = [];
  private isDocumentRuleCreateEditModalDisplayed = false;
  private shouldUseHeaderAndLinesFromDocumentEntry = false;

  @Mutation
  private setSupplierSelectedOnDocumentEntryMutation(supplier: any) {
    this.supplierSelectedOnDocumentEntry = Object.assign(
      {},
      this.supplierSelectedOnDocumentEntry,
      supplier
    );
  }

  @Mutation
  private setCompanySelectedOnDocumentEntryMutation(company: any) {
    this.companySelectedOnDocumentEntry = Object.assign(
      {},
      this.companySelectedOnDocumentEntry,
      company
    );
  }

  @Mutation
  private setCustomerAcctSelectedOnDocumentEntryMutation(customerAcct: any) {
    this.customerAcctSelectedOnDocumentEntry = Object.assign(
      {},
      this.customerAcctSelectedOnDocumentEntry,
      customerAcct
    );
  }

  @Mutation
  private setDocumentRuleIDMutation(documentRuleID: number | null) {
    this.documentRuleID = documentRuleID;
  }

  @Mutation
  private setPrototypeDocumentRuleIDMutation(documentRuleID: number | null) {
    this.prototypeDocumentRuleID = documentRuleID;
  }

  @Mutation
  private setIsNewDocumentRuleMutation(isNew: boolean) {
    this.isNewDocumentRule = isNew;
  }

  @Mutation
  private setDocumentRuleModalModeMutation(mode: DocumentRuleModalMode) {
    this.documentRuleModalMode = mode;
  }

  @Mutation
  private setDocumentRuleLinesMutation(lines: line[]) {
    this.documentRuleLines = lines;
  }

  @Mutation
  private setInitialDocumentRuleHeaderFieldsMutation() {
    // hydrate initial settings from doc entry page
    this.documentRuleHeaderFields.setSupplierFromDocumentEntry(
      this.supplierSelectedOnDocumentEntry as ValidatableField
    );
    this.documentRuleHeaderFields.setCompanyFromDocumentEntry(
      this.companySelectedOnDocumentEntry as ValidatableField
    );
    this.documentRuleHeaderFields.setCustomerAcctFromDocumentEntry(
      this.customerAcctSelectedOnDocumentEntry as ValidatableField
    );
  }

  @Mutation
  private setHeaderFieldsForEditingMutation(request: {
    fields: InvoiceHeaderFieldsResponse[];
    shouldSetInitialSettings?: boolean;
  }) {
    this.documentRuleHeaderFields.setExistingValuesForEditMode(
      request.fields,
      request.shouldSetInitialSettings ?? false
    );
  }

  @Mutation
  private setHeaderFieldComplexStateMutation(request: {
    property: string;
    value:
      | DocumentRuleAutocompleteInput
      | DocumentRuleSelectInput
      | DocumentRuleTextInput
      | string;
  }) {
    this.documentRuleHeaderFields[request.property] = request.value;
  }

  @Mutation
  private revertToDocumentEntryValue(property: string) {
    // only for company and customer acct
    if (property == "company") {
      this.documentRuleHeaderFields.company = Object.assign(
        {},
        this.documentRuleHeaderFields.company,
        {
          ...this.documentRuleHeaderFields.company,
          options:
            (this.companySelectedOnDocumentEntry as ValidatableField)
              ?.options ?? [],
          value: (this.companySelectedOnDocumentEntry as ValidatableField)
            ?.value as string | null
        }
      );
    }
    if (property == "customeracct") {
      this.documentRuleHeaderFields.customeracct = Object.assign(
        {},
        this.documentRuleHeaderFields.customeracct,
        {
          ...this.documentRuleHeaderFields.customeracct,
          value:
            ((this.customerAcctSelectedOnDocumentEntry as ValidatableField)
              ?.value as string) ?? ""
        }
      );
    }
  }

  @Mutation
  private setDocumentRuleStepAsCompletedMutation(request: {
    stepNumber: string;
    isComplete: boolean;
  }) {
    const key = request.stepNumber;
    const value = request.isComplete;
    Vue.set(this.isDocumentRuleComplete, key, value);
  }

  @Mutation
  private openPanelInDocumentRuleModalMutation(stepIndex: number | number[]) {
    if (
      typeof stepIndex == "number" &&
      !this.openedModalPanels.includes(stepIndex)
    ) {
      this.openedModalPanels.push(stepIndex);
    } else if (typeof stepIndex != "number") {
      this.openedModalPanels = stepIndex;
    }
  }

  @Mutation
  private setDocumentRuleUsagesMutation(ruleUsages: any[]) {
    this.documentRuleUsages = ruleUsages;
  }

  @Mutation
  private resetEntireDocumentRuleStoredStateMutation() {
    this.documentRuleModalMode = DocumentRuleModalMode.CREATE;

    this.isDocumentRuleComplete = {
      one: false,
      two: false,
      three: false
    };

    this.openedModalPanels = [0];

    this.documentRuleID = null;

    this.prototypeDocumentRuleID = null;

    this.isNewDocumentRule = true;

    this.documentRuleLines = [];

    this.documentRuleHeaderFields = new DocumentRuleHeaderFields();

    this.shouldUseHeaderAndLinesFromDocumentEntry = false;
  }

  @Mutation
  private setDocumentRuleTypeMutation(ruleType: InputObject) {
    this.documentRuleType = ruleType;
  }

  @Mutation
  private setDocumentRulesListForSupplierMutation(
    list: RavenApiDocumentRuleDataResponse[]
  ) {
    this.documentRulesListForSupplier = list;
  }

  @Mutation
  private openDocumentRuleCreateEditModalMutation(
    modalMode: DocumentRuleModalMode
  ) {
    this.documentRuleModalMode = modalMode;

    if (modalMode === DocumentRuleModalMode.CREATE) {
      // create modal
      this.isNewDocumentRule = true;
      this.isDocumentRuleComplete = {
        one: false,
        two: false,
        three: false
      };
      this.openedModalPanels = [0];
    } else {
      // edit/update modal
      this.isNewDocumentRule = false;
      this.isDocumentRuleComplete = {
        one: true,
        two: false,
        three: false
      };
      this.openedModalPanels = [0, 1];
    }

    this.isDocumentRuleCreateEditModalDisplayed = true;
  }

  @Mutation
  private changeStateOfCreateEditModalMutation(isModalDisplyed: boolean) {
    this.isDocumentRuleCreateEditModalDisplayed = isModalDisplyed;
  }

  @Mutation
  private setShouldUseHeaderAndLinesFromDocumentEntryMutation(
    shouldUseDocumentEntryValues: boolean
  ) {
    this.shouldUseHeaderAndLinesFromDocumentEntry = shouldUseDocumentEntryValues;
  }

  @Action
  public setSupplierSelectedOnDocumentEntry(supplier: any) {
    this.context.commit("setSupplierSelectedOnDocumentEntryMutation", supplier);
  }

  @Action
  public setCompanySelectedOnDocumentEntry(company: any) {
    this.context.commit("setCompanySelectedOnDocumentEntryMutation", company);
  }

  @Action
  public setCustomerAcctSelectedOnDocumentEntry(customerAcct: any) {
    this.context.commit(
      "setCustomerAcctSelectedOnDocumentEntryMutation",
      customerAcct
    );
  }

  @Action
  public setDocumentRuleID(documentRuleID: number | null) {
    this.context.commit("setDocumentRuleIDMutation", documentRuleID);
  }

  @Action
  public setPrototypeDocumentRuleID(documentRuleID: number | null) {
    this.context.commit("setPrototypeDocumentRuleIDMutation", documentRuleID);
  }

  @Action
  public setIsNewDocumentRule(isNew: boolean) {
    this.context.commit("setIsNewDocumentRuleMutation", isNew);
  }

  @Action
  public setDocumentRuleModalMode(mode: DocumentRuleModalMode) {
    this.context.commit("setDocumentRuleModalModeMutation", mode);
  }

  @Action
  public setDocumentRuleLines(lines: line[]) {
    this.context.commit("setDocumentRuleLinesMutation", lines);
  }

  @Action
  public setInitialDocumentRuleHeaderFields() {
    this.context.commit("setInitialDocumentRuleHeaderFieldsMutation");
  }

  @Action
  public setHeaderFieldsForEditing(request: {
    fields: InvoiceHeaderFieldsResponse[];
    shouldSetInitialSettings?: boolean;
  }) {
    this.context.commit("setHeaderFieldsForEditingMutation", request);
  }

  @Action
  public setHeaderFieldsComplexState(request: {
    property: string;
    value:
      | DocumentRuleAutocompleteInput
      | DocumentRuleSelectInput
      | DocumentRuleTextInput
      | string;
  }) {
    this.context.commit("setHeaderFieldComplexStateMutation", request);
  }

  @Action
  public revertInitialSettingToDocumentEntryValue(property: string) {
    this.context.commit("revertToDocumentEntryValue", property);
  }

  @Action
  public setDocumentRuleStepAsCompleted(request: {
    stepNumber: string;
    isComplete: boolean;
  }) {
    this.context.commit("setDocumentRuleStepAsCompletedMutation", request);
  }

  @Action
  public openPanelInDocumentRuleModal(stepIndex: number | number[]) {
    this.context.commit("openPanelInDocumentRuleModalMutation", stepIndex);
  }

  @Action
  public setDocumentRuleUsages(ruleUsages) {
    this.context.commit("setDocumentRuleUsagesMutation", ruleUsages);
  }

  @Action
  public resetEntireDocumentRuleStoredState() {
    this.context.commit("resetEntireDocumentRuleStoredStateMutation");
  }

  @Action
  public setDocumentRuleType(ruleType: InputObject) {
    this.context.commit("setDocumentRuleTypeMutation", ruleType);
  }

  @Action
  public setDocumentRulesListForSupplier(
    list: RavenApiDocumentRuleDataResponse[]
  ) {
    this.context.commit("setDocumentRulesListForSupplierMutation", list);
  }

  @Action
  public openDocumentRuleCreateEditModal(modalMode: DocumentRuleModalMode) {
    this.context.commit("openDocumentRuleCreateEditModalMutation", modalMode);
  }

  @Action
  public changeStateOfCreateEditModal(isModalDisplyed: boolean) {
    this.context.commit(
      "changeStateOfCreateEditModalMutation",
      isModalDisplyed
    );
  }

  @Action
  public setShouldUseHeaderAndLinesFromDocumentEntry(
    shouldUseDocumentEntryValues: boolean
  ) {
    this.context.commit(
      "setShouldUseHeaderAndLinesFromDocumentEntryMutation",
      shouldUseDocumentEntryValues
    );
  }

  public get getSupplierSelectedOnDocumentEntry(): any {
    return this.supplierSelectedOnDocumentEntry;
  }

  public get getCompanySelectedOnDocumentEntry(): any {
    return this.companySelectedOnDocumentEntry;
  }

  public get getCustomerAcctSelectedOnDocumentEntry(): any {
    return this.customerAcctSelectedOnDocumentEntry;
  }

  public get getDocumentRuleID(): number | null {
    return this.documentRuleID;
  }

  public get getPrototypeDocumentRuleID(): number | null {
    return this.prototypeDocumentRuleID;
  }

  public get getIsNewDocumentRule(): boolean {
    return this.isNewDocumentRule;
  }

  public get getDocumentRuleModalMode(): DocumentRuleModalMode {
    return this.documentRuleModalMode;
  }

  public get getDocumentRuleLines(): line[] {
    return this.documentRuleLines;
  }

  public get getDocumentRuleHeaderFields(): DocumentRuleHeaderFields {
    return this.documentRuleHeaderFields;
  }

  public get getIsDocumentRuleComplete(): { [key: string]: boolean } {
    return this.isDocumentRuleComplete;
  }

  public get getOpenedModalPanels(): number[] {
    return this.openedModalPanels;
  }

  public get getDocumentRuleUsages(): any[] {
    return this.documentRuleUsages;
  }

  public get getDocumentRuleType(): InputObject {
    return this.documentRuleType;
  }

  public get getDocumentRulesListForSupplier(): RavenApiDocumentRuleDataResponse[] {
    return this.documentRulesListForSupplier;
  }

  public get getIsDocumentRuleCreateEditModalDisplayed(): boolean {
    return this.isDocumentRuleCreateEditModalDisplayed;
  }

  public get getShouldUseHeaderAndLinesFromDocumentEntry(): boolean {
    return this.shouldUseHeaderAndLinesFromDocumentEntry;
  }
}

export default DocumentRulesStore;

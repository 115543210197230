import { RouteConfig } from "vue-router";
import AppRoles from "@/shared/constants/application-roles";

export const userRoutes: Array<RouteConfig> = [
  {
    path: "/users/configuration",
    name: "User Management",
    component: () =>
      import(
        /* webpackChunkName: "UserConfiguration" */ "@/views/users/UserConfiguration.vue"
      ),
    meta: {
      title: "Users",
      requiresAuth: true,
      customPermissions: ["shouldShowUserManagement"]
    }
  }
];

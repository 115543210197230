import { app } from "@/main";

class CustomerService {
  public async getCustomerRegion() {
    const response = await app.$ravenapi.get("api/customer/region");
    return response.data?.data ?? process.env.VUE_APP_REGION;
  }
}

export const customerService = new CustomerService(); // singleton

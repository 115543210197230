// below method is to avoid redundant navigation to current path
// which Vue considers to be an error
export const checkCurrentRouteAndRedirect = (router: any, path: any) => {
  const {
    currentRoute: { path: curPath }
  } = router;
  if (curPath !== path) router.push({ path });
};

export const handleBadRequest = (error: any) => {
  if (
    error?.config &&
    error?.config?.url &&
    error?.config?.url?.includes("/payments/")
  ) {
    return error.response;
  }
  return Promise.reject(error);
};

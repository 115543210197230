
































































































import { Component, Watch } from "vue-property-decorator";
import AWS from "aws-sdk";
import UserRolesMixin from "@/mixins/UserRoles.vue";
import IconButton from "@/components/design-system/buttons/IconButton.vue";
import ChatbotHeader from "@/components/chatbot/ChatbotHeader.vue";
import UserChatRequestHeader from "@/components/chatbot/UserChatRequestHeader.vue";
import { ChatTransaction } from "@/models/chatbot/chat-transaction";
import { ChatMessage } from "@/models/chatbot/chat-message";
import { randomNumberGenerator } from "@/helpers/numbers-helpers";

// Initialize the Amazon Cognito credentials provider
AWS.config.region = process.env.VUE_APP_REGION; // Region
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: process.env.VUE_APP_AWS_COGNITO_IDENTITY_POOL_ID as string
});

const lexruntime = new AWS.LexRuntimeV2();
const lexUserId = "chatbot-demo" + Date.now();

@Component({
  components: {
    "icon-button": IconButton,
    "chatbot-header": ChatbotHeader,
    "user-chat-request-header": UserChatRequestHeader
  }
})
export default class AIChatBot extends UserRolesMixin {
  private chatInput = "";
  private isChatInputLocked = false;
  private isChatbotOpen = false;
  private isGreetingComplete = false;
  private isLoadingResponse = false;

  private chatTransactions: ChatTransaction[] = [];

  private get conversationDiv(): HTMLElement | null {
    return document.getElementById("conversation");
  }

  pushChat() {
    // if there is text to be sent...
    if (this.chatInput && this.chatInput?.trim().length > 0) {
      // disable input to show we're sending it
      const chatInput = this.chatInput.trim();
      this.chatInput = "";
      this.isChatInputLocked = true;

      this.showRequest(chatInput);
      this.sendToLex(chatInput);
    }
  }

  sendToLex(text: string) {
    const params = {
      botAliasId: process.env.VUE_APP_CHATBOT_ALIAS_ID as string,
      botId: process.env.VUE_APP_CHATBOT_ID as string,
      localeId: "en_US",
      text: text,
      sessionId: lexUserId,
      sessionState: {
        sessionAttributes: {
          authorization: this.userStore.getAccessToken ?? "",
          customerId: this.userStore.getSelectedCustomer?.id?.toString() ?? ""
        }
      }
    };

    this.isLoadingResponse = true;
    lexruntime.recognizeText(
      params,
      (err: AWS.AWSError, data: AWS.LexRuntimeV2.RecognizeTextResponse) => {
        this.isLoadingResponse = false;
        if (err) {
          this.showError(
            "I'm sorry, I'm having trouble connecting to the server. Please try again later."
          );
        }
        if (data) {
          // show response and/or error/dialog status
          this.showResponse(data);
        }
        // re-enable input
        this.isChatInputLocked = false;
      }
    );
  }

  pushChatOnEnterKeystroke(event: KeyboardEvent) {
    if (event?.key === "Enter" || event?.code === "Enter") {
      this.pushChat();
    } else {
      return;
    }
  }

  adjustScrollPosition() {
    if (this.conversationDiv) {
      this.conversationDiv.scrollTop = this.conversationDiv.scrollHeight;
    }
  }

  addChatTransaction(to: string, messages: ChatMessage[], hasError: boolean) {
    this.chatTransactions.push(
      new ChatTransaction(
        this.chatTransactions.length + 1,
        to,
        messages,
        hasError
      )
    );
  }

  showRequest(theTextInput: string) {
    const message = new ChatMessage(
      randomNumberGenerator(),
      "<p>" + theTextInput + "</p>"
    );
    this.addChatTransaction("chatbot", [message], false);
    this.$nextTick(() => this.adjustScrollPosition());
  }

  showError(theTextInput: string) {
    const message = new ChatMessage(
      randomNumberGenerator(),
      "<p>" + theTextInput + "</p>"
    );
    this.addChatTransaction("user", [message], true);
    this.$nextTick(() => this.adjustScrollPosition());
  }

  showResponse(lexResponse: AWS.LexRuntimeV2.RecognizeTextResponse) {
    const messages: ChatMessage[] = [];

    if (lexResponse.messages && lexResponse.messages.length > 0) {
      lexResponse.messages.forEach((msg: any) => {
        messages.push(
          new ChatMessage(randomNumberGenerator(), "<p>" + msg.content + "</p>")
        );
      });
    }

    if (messages.length > 0) {
      this.addChatTransaction("user", messages, false);
    }

    this.$nextTick(() => this.adjustScrollPosition());
  }

  endChat() {
    this.isChatbotOpen = false;
  }

  //watchers
  @Watch("isChatbotOpen")
  onChatbotOpen() {
    //only greet first time opening
    if (!this.isGreetingComplete) {
      this.sendToLex("Default Greeting");
      this.isGreetingComplete = true;
    }
  }
}

















import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class AsscendLogo extends Vue {
  @Prop({ default: "#010101" }) color!: string;
  @Prop({ default: "630.503" }) width!: string;
  @Prop({ default: "246.782" }) height!: string;
}

import { FieldCoordinates } from "@/models/document-entry/pdf-coordinates";
import { PDFPage } from "@/models/document-entry/pdf-page";
import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import _ from "lodash";

const debouncedUpdateScaleInLocalStorage = _.debounce((scale: number) => {
  localStorage.setItem("pdfScale", JSON.stringify({ scale: scale }));
}, 250);

const FetchScaleFromLocalStorage = () => {
  const { scale } = JSON.parse(
    localStorage.getItem("pdfScale") ?? `{"scale": 1.5}`
  );
  return scale;
};

@Module({ name: "DocViewerStore", namespaced: true })
class DocViewerStore extends VuexModule {
  private pdfScale = FetchScaleFromLocalStorage();
  private pdfDocument: string | undefined = undefined;
  private pdfCoordinates: FieldCoordinates[] = [];
  private pdfCanvases: PDFPage[] = [];
  private focusedField = "";
  private isEDIDocument = false;
  private hasEmailBody = false;
  private pdfWebViewerKey = 1; // incremented when image loads to reload doc-viewer

  @Mutation
  private setPdfScaleMutation(scale: number) {
    this.pdfScale = scale;
  }

  @Mutation
  private setPdfDocumentMutation(pdfDocument: string | undefined) {
    this.pdfDocument = pdfDocument;
  }

  @Mutation
  private setPdfCoordinatesMutation(coordinates: FieldCoordinates[]) {
    this.pdfCoordinates = coordinates;
  }

  @Mutation
  private setFocusedFieldMutation(field: string) {
    this.focusedField = field;
  }

  @Mutation
  private setPdfCanvasesMutation(pdfCanvases: PDFPage[]) {
    this.pdfCanvases = pdfCanvases;
  }

  @Mutation
  private setIsEDIDocumentMutation(isEDIDocument: boolean) {
    this.isEDIDocument = isEDIDocument;
  }

  @Mutation
  private setHasEmailBodyMutation(hasEmailBody: boolean) {
    this.hasEmailBody = hasEmailBody;
  }

  @Mutation
  private incrementPdfWebViewerKeyMutation() {
    this.pdfWebViewerKey++;
  }

  @Action
  public setPdfScale(scale: number) {
    debouncedUpdateScaleInLocalStorage(scale);
    this.context.commit("setPdfScaleMutation", scale);
  }

  @Action
  public setPdfDocument(pdfDocument: string | undefined) {
    this.context.commit("setPdfDocumentMutation", pdfDocument);
  }

  @Action
  public setPdfCoordinates(coordinates: FieldCoordinates[]) {
    this.context.commit("setPdfCoordinatesMutation", coordinates);
  }

  @Action
  public setPdfCanvases(pdfCanvases: PDFPage[]) {
    this.context.commit("setPdfCanvasesMutation", pdfCanvases);
  }

  @Action
  public setFocusedField(field: string) {
    this.context.commit("setFocusedFieldMutation", field);
  }

  @Action
  public setIsEDIDocument(isEDIDocument: boolean) {
    this.context.commit("setIsEDIDocumentMutation", isEDIDocument);
  }

  @Action
  public setHasEmailBody(hasEmailBody: boolean) {
    this.context.commit("setHasEmailBodyMutation", hasEmailBody);
  }

  @Action
  public incrementPdfWebViewerKey() {
    this.context.commit("incrementPdfWebViewerKeyMutation");
  }

  public get getPdfScale(): number {
    return this.pdfScale;
  }

  public get getPdfDocument(): string | undefined {
    return this.pdfDocument;
  }

  public get getPdfCoordinates(): FieldCoordinates[] {
    return this.pdfCoordinates;
  }

  public get getPdfCanvases(): PDFPage[] {
    return this.pdfCanvases;
  }

  public get getfocusedField(): string {
    return this.focusedField;
  }

  public get getIsEDIDocument(): boolean {
    return this.isEDIDocument;
  }

  public get getHasEmailBody(): boolean {
    return this.hasEmailBody;
  }

  public get getPdfWebViewerKey(): number {
    return this.pdfWebViewerKey;
  }
}

export default DocViewerStore;

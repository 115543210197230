



















import { Vue, Component, Prop } from "vue-property-decorator";
import IconButton from "@/components/design-system/buttons/IconButton.vue";
@Component({
  components: { IconButton }
})
export default class SecondaryButton extends Vue {
  @Prop() buttonText!: string;
  @Prop() handleClick!: Function;
  @Prop() handleTab?: Function;
  @Prop() materialIconName: string | undefined;
  @Prop() isButtonDisabled?: boolean;
  @Prop({ default: "" }) width?: string;
  @Prop({ default: "" }) customClass?: string;

  private materialIconNameValue = this.materialIconName || "";
}

import { RouteConfig } from "vue-router";

export const internalOnlyRoutes: Array<RouteConfig> = [
  {
    path: "/internal-only/select-customer",
    name: "Select Customer",
    component: () =>
      import(
        /* webpackChunkName: "Select Customer" */ "@/views/internal-only/SelectCustomer.vue"
      ),
    meta: {
      title: "",
      layout: "minimal",
      requiresAuth: true,
      customPermissions: ["isInternalUser"] // strings here must have a corresponding function in @/helpers/user-roles customPermissions object
    }
  },
  {
    path: "/internal-only/demo-settings",
    name: "Demo Settings",
    component: () =>
      import(
        /* webpackChunkName: "DemoSettings" */ "@/views/internal-only/DemoSettings.vue"
      ),
    meta: {
      title: "Configurations",
      requiresAuth: true,
      customPermissions: ["isInternalDemoAccount"]
    }
  },
  {
    path: "/internal-only/tenants",
    name: "Tenant Onboarding",
    component: () =>
      import(
        /* webpackChunkName: "Manage Tenants" */ "@/views/internal-only/Tenants.vue"
      ),
    meta: {
      title: "Tenant Onboarding",
      requiresAuth: true,
      customPermissions: ["shouldShowTenantManagement"]
    }
  }
];

import { RouteConfig } from "vue-router";
import { LoginCallback } from "@okta/okta-vue";
import AppRoles from "@/shared/constants/application-roles";

const errorMetadata = {
  tite: "",
  layout: "error"
};

export const baseRoutes: Array<RouteConfig> = [
  {
    path: "*",
    redirect: "/404"
  },
  {
    path: "/404",
    name: "404",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "404" */ "@/views/NotFound.vue"),
    meta: errorMetadata
  },
  {
    path: "/403/access",
    name: "Unauthorized Access",
    component: () =>
      import(
        /* webpackChunkName: "403/access" */ "@/views/UnauthorizedAccess.vue"
      ),
    meta: errorMetadata
  },
  {
    path: "/403/permission",
    name: "No Application Roles Configured",
    component: () =>
      import(
        /* webpackChunkName: "403/permission" */ "@/views/NoRolesFound.vue"
      ),
    meta: errorMetadata
  },
  {
    path: "/403/document",
    name: "Unauthorized Document",
    component: () =>
      import(
        /* webpackChunkName: "403/document" */ "@/views/UnauthorizedDoc.vue"
      ),
    meta: errorMetadata
  },
  {
    path: "/503/healthcheck",
    name: "Services Unavailable",
    component: () =>
      import(
        /* webpackChunkName: "503/healthcheck" */ "@/views/ServicesUnavailable.vue"
      ),
    meta: errorMetadata
  },
  {
    // Okta callback route
    // The callback route is not seen by the user, and it's not the user's final destination.
    // It's just one step in the authentication redirect flow.
    path: "/login/callback",
    component: LoginCallback
  },
  {
    path: "/logout",
    name: "Logout",
    component: () =>
      import(/* webpackChunkName: "Logout" */ "@/views/Logout.vue")
  },
  {
    path: "/",
    name: "Dashboard",
    component: () =>
      import(/* webpackChunkName: "Dashboard" */ "@/views/Dashboard.vue"),
    meta: {
      title: "Dashboard",
      requiresAuth: true,
      authorize: [
        AppRoles.VS_MANAGER,
        AppRoles.AP_ADMIN,
        AppRoles.AP_MANAGER,
        AppRoles.AP_SPECIALIST,
        AppRoles.FINANCIAL_ACCOUNTING,
        AppRoles.USER_ADMIN,
        AppRoles.ASCEND_ADMIN
      ]
    }
  }
];

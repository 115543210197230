/* eslint-disable no-console */

import { register } from "register-service-worker";

// confirm browser supports service workers before registering
if ("serviceWorker" in navigator && process.env.NODE_ENV === "production") {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log("App is being served from cache by a service worker.");
    },
    registered(registration: ServiceWorkerRegistration) {
      console.log(
        "Service worker has been registered and is polling for updates."
      );
      setInterval(async () => {
        try {
          await registration.update();
        } catch (error) {
          console.log("Service worker unavailable");
        }
      }, 1000 * 60); // poll for updates every minute
    },
    cached() {
      console.log("Content has been cached for offline use.");
    },
    updatefound() {
      console.log("New content is downloading.");
    },
    updated(registration: ServiceWorkerRegistration) {
      console.log("New content is available; please refresh.");
      document.dispatchEvent(
        new CustomEvent("serviceWorkerUpdated", { detail: registration })
      );
    },
    offline() {
      console.log(
        "No internet connection found. App is running in offline mode."
      );
    },
    error(error) {
      console.error("Error during service worker registration:", error);
    }
  });
}

import AppRoles from "@/shared/constants/application-roles";

const analyticsAuthorizedRoles = [
  AppRoles.AP_MANAGER,
  AppRoles.FINANCIAL_ACCOUNTING,
  AppRoles.VS_MANAGER,
  AppRoles.ASCEND_ADMIN
];

export default [
  {
    title: "Invoices",
    icon: "mdi-menu",
    href: "/documents",
    group: "(documents|search)",
    subGroup: [
      {
        title: "My Invoices",
        href: "/documents"
      },
      {
        title: "Completed Invoices",
        href: "/complete-documents"
      },
      {
        title: "Advanced Search",
        href: "/document-search",
        internalGroups: ["shouldShowAdvancedSearch"]
      },
      {
        title: "Saved Searches",
        href: "/saved-document-searches",
        internalGroups: ["shouldShowAdvancedSearch"]
      }
    ]
  },
  {
    title: "Analytics",
    icon: "mdi-chart-line",
    href: "/analytics/recognition-rates",
    group: "analytics",
    authorize: analyticsAuthorizedRoles,
    subGroup: [
      {
        title: "Recognition Rates",
        href: "/analytics/recognition-rates",
        authorize: analyticsAuthorizedRoles
      },
      {
        title: "Recognition Analytics",
        href: "/analytics/recognition",
        authorize: analyticsAuthorizedRoles
      },
      {
        title: "Invoice Volume",
        href: "/analytics/invoice-volume",
        authorize: analyticsAuthorizedRoles
      },
      {
        title: "Invoice Analytics",
        href: "/analytics/invoices",
        authorize: analyticsAuthorizedRoles
      },
      {
        title: "Touchless Processing",
        href: "/analytics/touchless-processing",
        authorize: analyticsAuthorizedRoles
      },
      {
        title: "Licensing",
        href: "/analytics/licensing",
        authorize: analyticsAuthorizedRoles,
        internalGroups: ["shouldShowLicensingAnalytics"]
      }
    ]
  }
];

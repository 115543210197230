import { RouteConfig } from "vue-router";
import AppRoles from "@/shared/constants/application-roles";

export const documentsRoutes: Array<RouteConfig> = [
  {
    path: "/documents",
    name: "Invoice List",
    component: () =>
      import(
        /* webpackChunkName: "DocumentList" */ "@/views/documents/DocumentList.vue"
      ),
    meta: {
      title: "Invoices",
      requiresAuth: true
    }
  },
  {
    path: "/complete-documents",
    name: "Invoices",
    component: () =>
      import(
        /* webpackChunkName: "CompleteDocuments" */ "@/views/documents/CompleteDocuments.vue"
      ),
    meta: {
      title: "Invoices",
      requiresAuth: true
    }
  },
  {
    path: "/saved-document-searches",
    name: "Saved Searches",
    component: () =>
      import(
        /* webpackChunkName: "Saved Searches" */ "@/views/documents/SavedDocumentSearches.vue"
      ),
    meta: {
      title: "Saved Searches",
      requiresAuth: true,
      customPermissions: ["shouldShowAdvancedSearch"]
    }
  },
  {
    path: "/document-search",
    name: "Advanced Search",
    component: () =>
      import(
        /* webpackChunkName: "Advanced Search" */ "@/views/documents/DocumentSearch.vue"
      ),
    meta: {
      title: "Advanced Search",
      requiresAuth: true,
      customPermissions: ["shouldShowAdvancedSearch"]
    }
  },
  {
    path: "/document-search/:id",
    name: "Saved Search",
    component: () =>
      import(
        /* webpackChunkName: "Saved Search" */ "@/views/documents/DocumentSearch.vue"
      ),
    meta: {
      title: "Saved Search",
      requiresAuth: true,
      customPermissions: ["shouldShowAdvancedSearch"]
    }
  },
  {
    path: "/document/:id",
    name: "Invoice Entry",
    component: () =>
      import(
        /* webpackChunkName: "DocumentEntry" */ "@/views/documents/DocumentEntry.vue"
      ),
    meta: {
      layout: "minimal", // specific layout for this page
      title: "Invoice Entry",
      requiresAuth: true,
      authorize: [
        AppRoles.AP_SPECIALIST,
        AppRoles.AP_MANAGER,
        AppRoles.VS_SPECIALIST,
        AppRoles.VS_MANAGER,
        AppRoles.FINANCIAL_ACCOUNTING,
        AppRoles.ASCEND_ADMIN
      ]
    }
  },
  {
    path: "/document/:id/image/:invoiceId",
    name: "Document Image",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "DocumentImage" */ "@/views/documents/DocumentImage.vue"
      ),
    meta: {
      layout: "minimal", // specific layout for this page
      title: "Document Image",
      requiresAuth: true,
      authorize: [
        AppRoles.AP_SPECIALIST,
        AppRoles.AP_MANAGER,
        AppRoles.VS_SPECIALIST,
        AppRoles.VS_MANAGER,
        AppRoles.FINANCIAL_ACCOUNTING,
        AppRoles.ASCEND_ADMIN
      ]
    }
  }
];

export class DocumentRuleAutocompleteInput {
  public inputType: string;
  public value: string | number | null;
  public placeholder: string;
  public isValid: boolean;
  public hasError: boolean;
  public loading: boolean;
  public options: any[];
  public search: null | string;
  public lookupResultsPageOptions: {
    [key: string]: number;
  };
  public errorMessage?: string;

  constructor(
    value?: string | number | null,
    text?: string,
    isValid?: boolean,
    placeholder?: string
  ) {
    const option = {
      id: value ?? null,
      displayValue: text ?? "",
      description: text ?? ""
    };
    this.inputType = "autocomplete";
    this.value = value ?? null;
    this.placeholder = placeholder ?? "";
    this.isValid = isValid ?? true;
    this.hasError = false;
    this.loading = false;
    this.options = (text?.length ?? 0) > 0 ? [option] : [];
    this.search = "";
    this.lookupResultsPageOptions = {
      page: 0,
      totalPages: 0
    };
    this.errorMessage = "";
  }
}

export class DocumentRuleSelectInput {
  public inputType: string;
  public value: string | number | { text: string; value: number };
  public isValid: boolean;
  public hasError: boolean;
  public options: any[];
  public errorMessage?: string;

  constructor(
    value?: string | number | { text: string; value: number },
    options?: any[],
    isValid?: boolean
  ) {
    this.inputType = "select";
    this.value = value ?? "";
    this.isValid = isValid ?? true;
    this.hasError = false;
    this.options = options ?? [];
    this.errorMessage = "";
  }
}

export class DocumentRuleTextInput {
  public inputType: string;
  public value: string;
  public placeholder: string;
  public isValid: boolean;
  public hasError: boolean;
  public errorMessage?: string;

  constructor(value?: string, placeholder?: string, isValid?: boolean) {
    this.inputType = "text";
    this.value = value ?? "";
    this.placeholder = placeholder ?? "";
    this.isValid = isValid ?? true;
    this.hasError = false;
    this.errorMessage = "";
  }
}

import { RouteConfig } from "vue-router";
import AppRoles from "@/shared/constants/application-roles";

export const paymentsRoutes: Array<RouteConfig> = [
  {
    path: "/payments/activity",
    name: "Payment Activity",
    component: () =>
      import(
        /* webpackChunkName: "PaymentActivity" */ "@/views/payments/PaymentActivity.vue"
      ),
    meta: {
      title: "Payments",
      requiresAuth: true,
      authorize: [AppRoles.ASCEND_ADMIN, AppRoles.PAYMENTS_SPECIALIST],
      customPermissions: ["shouldShowPayments"]
    }
  },
  {
    path: "/payments/settlements",
    name: "Settlement Run Activity",
    component: () =>
      import(
        /* webpackChunkName: "SettlementRunActivity" */ "@/views/payments/SettlementRunActivity.vue"
      ),
    meta: {
      title: "Payments",
      requiresAuth: true,
      authorize: [AppRoles.ASCEND_ADMIN, AppRoles.PAYMENTS_SPECIALIST],
      customPermissions: ["shouldShowPayments"]
    }
  },
  {
    path: "/payments/upgrade",
    name: "Upgrade",
    component: () =>
      import(
        /* webpackChunkName: "UpgradeToPayments" */ "@/views/payments/UpgradeToPayments.vue"
      ),
    meta: {
      title: "Payments",
      requiresAuth: true,
      authorize: [AppRoles.ASCEND_ADMIN],
      customPermissions: ["shouldShowPayments"]
    }
  }
];





































































import { Component } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import NavSidebar from "@/components/layout/NavSidebar.vue";
import ValidationServicesNavSidebar from "@/components/layout/ValidationServicesNavSidebar.vue";
import AIChatBot from "@/components/chatbot/AIChatbot.vue";
import NavSidebarStore from "@/store/nav-sidebar.store";
import CustomerSelector from "@/components/internal-only/CustomerSelector.vue";
import UserRolesMixin from "@/mixins/UserRoles.vue";

@Component({
  components: {
    "nav-sidebar": NavSidebar,
    "customer-selector": CustomerSelector,
    "vs-sidebar": ValidationServicesNavSidebar,
    "ai-chatbot": AIChatBot
  }
})
export default class DefaultLayout extends UserRolesMixin {
  private readonly navSidebarStore: NavSidebarStore = getModule(
    NavSidebarStore,
    this.$store
  );
  private shouldShowNotificationCenter: boolean = this.$launchDarkly.variation(
    "show-notification-center",
    false
  );

  private get sidebarShow() {
    return this.navSidebarStore.getSidebarShow;
  }
  private get authenticated() {
    return this.userStore.getAuthenticationState;
  }
  private get showCustomerSelectorInAppBar() {
    if (this.user) {
      return this.isAscendUser && this.isCustomerFacingEnv;
    } else {
      return false;
    }
  }
  private get shouldShowChatbot() {
    const flags = this.userStore.getFeatureFlagSet;
    return flags
      ? flags["show-ai-chatbot"] ?? false
      : this.$launchDarkly.variation("show-ai-chatbot", false);
  }

  toggleDrawer() {
    this.navSidebarStore.setSidebarShow(!this.sidebarShow);
  }

  async login() {
    await this.$auth.signInWithRedirect();
  }

  async logout() {
    await this.$auth.signOut({
      postLogoutRedirectUri: window.location.origin + "/logout"
    });
    // clear tokens
    this.$auth.tokenManager.clear();
    // update launch-darkly for non-authenticated user
    await this.$launchDarkly.identify({
      anonymous: true
    });
  }
}

import Vue from "vue";
import * as LDClient from "launchdarkly-js-client-sdk";
import store from "@/store";

const user = store.getters["UserStore/getUser"];
const customer = store.getters["UserStore/getSelectedCustomer"];

const ldUser: LDClient.LDUser = user
  ? {
      key: user?.email,
      name: user?.name,
      email: user?.email,
      custom: {
        groups: user?.groups ?? [],
        approle: user?.approle ? user?.approle : [],
        customerid: user?.customerid ?? 0,
        region: customer ? customer.region : process.env.VUE_APP_REGION,
        env: process.env.VUE_APP_ENVIRONMENT as string
      },
      anonymous: false
    }
  : {
      anonymous: true
    };

// initialize LaunchDarkly with anonomous user
// client ID is not a secret and can be stored in the code per LD docs
const _launchDarklyCLient = LDClient.initialize(
  "6079ab799b83b00c17800957",
  ldUser
);

if (!ldUser.anonymous) {
  _launchDarklyCLient.identify(ldUser);
}

_launchDarklyCLient.setStreaming(true);

const commitFeatureFlagChange = () => {
  return store.commit(
    "UserStore/setFeatureFlagSetMutation",
    _launchDarklyCLient.allFlags()
  );
};

_launchDarklyCLient.on("ready", () => {
  commitFeatureFlagChange();
});

_launchDarklyCLient.on("change", () => {
  commitFeatureFlagChange();
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function LaunchDarklyPlugin(vue: typeof Vue, options?: any): void {
  vue.prototype.$launchDarkly = _launchDarklyCLient;
}

declare module "vue/types/vue" {
  interface Vue {
    $launchDarkly: any;
  }
}

export default LaunchDarklyPlugin;

import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import moment from "moment";
import { CategoryChartData } from "@/models/reporting-analytics/category-chart-data";
import { TimelineChartData } from "@/models/reporting-analytics/timeline-data";

@Module({ name: "RecognitionRateAnalyticsStore", namespaced: true })
class RecognitionRateAnalyticsStore extends VuexModule {
  private dateRange: { [key: string]: string } = {
    startDate: moment()
      .subtract(1, "weeks")
      .format()
      .toString()
      .substring(0, 10),
    endDate: moment()
      .format()
      .toString()
      .substring(0, 10)
  };
  private recogByHeaderFieldData: {
    [key: string]: CategoryChartData[];
  } = {};
  private recogBySupplierData: CategoryChartData[] = [];
  private supplierInvoiceVolumeData: CategoryChartData[] = [];
  private recogHistoricalData: {
    [key: string]: TimelineChartData[];
  } = {};
  private averageOverallRecogRate = "0%";
  private fieldsAnalyzed = "0";
  private numberOfSuppliers = 0;
  private searchButtonClicked = 0;

  @Mutation
  private setDateRange(dateRange: { [key: string]: string }) {
    this.dateRange = dateRange;
  }

  @Mutation
  private setRecogByHeaderFieldData(data: {
    [key: string]: CategoryChartData[];
  }) {
    this.recogByHeaderFieldData = data;
  }

  @Mutation
  private setRecogBySupplierData(data: CategoryChartData[]) {
    this.recogBySupplierData = data;
  }

  @Mutation
  private setSupplierInvoiceVolumeData(data: CategoryChartData[]) {
    this.supplierInvoiceVolumeData = data;
  }

  @Mutation
  private setRecogHistoricalData(data: { [key: string]: TimelineChartData[] }) {
    this.recogHistoricalData = data;
  }

  @Mutation
  private setAverageOverallRecogRate(rate: string) {
    this.averageOverallRecogRate = rate;
  }

  @Mutation
  private setFieldsAnalyzed(numOfFields: string) {
    this.fieldsAnalyzed = numOfFields;
  }

  @Mutation
  private setNumberOfSuppliers(numOfSuppliers: number) {
    this.numberOfSuppliers = numOfSuppliers;
  }

  @Mutation
  private setSearchButtonClicked() {
    this.searchButtonClicked += 1;
  }

  @Mutation
  private resetState() {
    this.recogByHeaderFieldData = {};
    this.recogBySupplierData = [];
    this.supplierInvoiceVolumeData = [];
    this.recogHistoricalData = {};
    this.averageOverallRecogRate = "0%";
    this.fieldsAnalyzed = "0";
    this.numberOfSuppliers = 0;
    this.searchButtonClicked = 0;
  }

  @Action({ rawError: true })
  public updateDateRange(dateRange: { [key: string]: string }) {
    this.context.commit("setDateRange", dateRange);
  }

  @Action({ rawError: true })
  public updateRecogByHeaderFieldData(data: {
    [key: string]: CategoryChartData[];
  }) {
    this.context.commit("setRecogByHeaderFieldData", data);
  }

  @Action({ rawError: true })
  public updateRecogBySupplierData(data: CategoryChartData[]) {
    this.context.commit("setRecogBySupplierData", data);
  }

  @Action({ rawError: true })
  public updateSupplierInvoiceVolumeData(data: CategoryChartData[]) {
    this.context.commit("setSupplierInvoiceVolumeData", data);
  }

  @Action({ rawError: true })
  public updateRecogHistoricalData(data: {
    [key: string]: TimelineChartData[];
  }) {
    this.context.commit("setRecogHistoricalData", data);
  }

  @Action({ rawError: true })
  public updateAverageOverallRecogRate(rate: string) {
    this.context.commit("setAverageOverallRecogRate", rate);
  }

  @Action({ rawError: true })
  public updateFieldsAnalyzed(numOfFields: number) {
    this.context.commit("setFieldsAnalyzed", numOfFields);
  }

  @Action({ rawError: true })
  public updateNumberOfSuppliers(numOfSuppliers: number) {
    this.context.commit("setNumberOfSuppliers", numOfSuppliers);
  }

  @Action({ rawError: true })
  public clickRecogSearchButton() {
    this.context.commit("setSearchButtonClicked");
  }

  @Action({ rawError: true })
  public resetToInitialState() {
    this.context.commit("resetState");
  }

  public get getDateRange(): { [key: string]: string } {
    return this.dateRange;
  }

  public get getRecogByHeaderFieldData(): {
    [key: string]: CategoryChartData[];
  } {
    return this.recogByHeaderFieldData;
  }

  public get getRecogBySupplierData(): CategoryChartData[] {
    return this.recogBySupplierData;
  }

  public get getSupplierInvoiceVolumeData(): CategoryChartData[] {
    return this.supplierInvoiceVolumeData;
  }

  public get getRecogHistoricalData(): {
    [key: string]: TimelineChartData[];
  } {
    return this.recogHistoricalData;
  }

  public get getAverageOverallRecogRate(): string {
    return this.averageOverallRecogRate;
  }

  public get getFieldsAnalyzed(): string {
    return this.fieldsAnalyzed;
  }

  public get getNumberOfSuppliers(): number {
    return this.numberOfSuppliers;
  }

  public get getSearchButtonClicked(): number {
    return this.searchButtonClicked;
  }
}

export default RecognitionRateAnalyticsStore;

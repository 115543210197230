import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import {
  ResultColumns,
  ValuesToSearch,
  AdvancedSearchItem
} from "@/interfaces/advanced-search/advanced-search-interfaces";

@Module({ name: "AdvancedSearchStore", namespaced: true })
class AdvancedSearchStore extends VuexModule {
  private searchID: string | number = 0;
  private resultsConfig: ResultColumns[] = [];
  private appliedValuesToSearch: ResultColumns[] = [];
  private appliedFieldsValuesConfig: ValuesToSearch[] = [];
  private searchResults: AdvancedSearchItem[] = [];

  @Mutation
  private setSearchIDMutation(searchID: string | number) {
    this.searchID = searchID;
  }

  @Mutation
  private setResultsConfigMutation(resultsConfig: ResultColumns[]) {
    this.resultsConfig = resultsConfig;
  }

  @Mutation
  private setAppliedValuesToSearchMutation(
    appliedValuesToSearch: ResultColumns[]
  ) {
    this.appliedValuesToSearch = appliedValuesToSearch;
  }

  @Mutation
  private setAppliedFieldsValuesConfigMutation(
    appliedFieldsValuesConfig: ValuesToSearch[]
  ) {
    this.appliedFieldsValuesConfig = appliedFieldsValuesConfig;
  }

  @Mutation
  private setSearchResultsMutation(searchResults: AdvancedSearchItem[]) {
    this.searchResults = searchResults;
  }

  @Mutation
  private clearStateFromPastSearchMutation() {
    this.searchID = 0;
    this.resultsConfig = [];
    this.appliedValuesToSearch = [];
    this.appliedFieldsValuesConfig = [];
    this.searchResults = [];
  }

  @Action({ rawError: true })
  public setSearchID(searchID: string | number) {
    this.context.commit("setSearchIDMutation", searchID);
  }

  @Action({ rawError: true })
  public setResultsConfig(resultsConfig: ResultColumns[]) {
    this.context.commit("setResultsConfigMutation", resultsConfig);
  }

  @Action({ rawError: true })
  public setAppliedValuesToSearch(appliedValuesToSearch: ResultColumns[]) {
    this.context.commit(
      "setAppliedValuesToSearchMutation",
      appliedValuesToSearch
    );
  }

  @Action({ rawError: true })
  public setAppliedFieldsValuesConfig(
    appliedFieldsValuesConfig: ValuesToSearch[]
  ) {
    this.context.commit(
      "setAppliedFieldsValuesConfigMutation",
      appliedFieldsValuesConfig
    );
  }

  @Action({ rawError: true })
  public setSearchResults(searchResults: AdvancedSearchItem[]) {
    this.context.commit("setSearchResultsMutation", searchResults);
  }

  @Action({ rawError: true })
  public clearStateFromPastSearch() {
    this.context.commit("clearStateFromPastSearchMutation");
  }

  public get getSearchID(): string | number {
    return this.searchID;
  }

  public get getResultsConfig(): ResultColumns[] {
    return this.resultsConfig;
  }

  public get getAppliedValuesToSearch(): ResultColumns[] {
    return this.appliedValuesToSearch;
  }

  public get getAppliedFieldsValuesConfig(): ValuesToSearch[] {
    return this.appliedFieldsValuesConfig;
  }

  public get getSearchResults(): AdvancedSearchItem[] {
    return this.searchResults;
  }
}

export default AdvancedSearchStore;

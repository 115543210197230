import { app } from "@/main";
import { RavenUserManagementAddRequest } from "@/models/external-services/raven-api-user-management-add-request";
import { RavenUserManagementUpdateRequest } from "@/models/external-services/raven-api-user-management-update-request";

class UserManagementService {
  // Load user management table
  public async loadUserTableDataAsync() {
    const apiResponse = await app.$ravenapi.get(`api/usermanagement/get`);
    return apiResponse.data;
  }

  // Create new user
  public async createNewUserAsync(request: RavenUserManagementAddRequest) {
    return await app.$ravenapi.post("api/usermanagement/add", request);
  }

  // Update user profile
  public async updateUserAsync(request: RavenUserManagementUpdateRequest) {
    return await app.$ravenapi.post("api/usermanagement/update", request);
  }

  // Delete user
  public async deleteUserAsync(userID: string) {
    const queryString =
      "api/usermanagement/delete/?userID=" + encodeURIComponent(userID);
    return await app.$ravenapi.delete(queryString);
  }

  // Deactivate user
  public async deactivateUserAsync(email: string) {
    const queryString =
      "api/usermanagement/deactivate?email=" + encodeURIComponent(email);
    return await app.$ravenapi.delete(queryString);
  }

  // GET list of user's group memberships
  private async findGroupsContainingUserAsync(): Promise<any[]> {
    return (await app.$ravenapi.get("api/usermanagement/groups")) as any[];
  }

  public getCustomerShortname(groups: string[]): string {
    const usersGroup = groups.find((group: any) => group.startsWith("USERS_"));
    return usersGroup?.substring(6) ?? "";
  }

  // Reset user password
  public async resetUserPasswordAsync(oktaId: string): Promise<any> {
    const endpoint = "api/usermanagement/resetpassword?oktaUserId=" + oktaId;
    return await app.$ravenapi.post(endpoint, {});
  }

  // Reset user MFA
  public async resetUserFactorsAsync(oktaId: string): Promise<any> {
    const endpoint = "api/usermanagement/resetfactors?oktaUserId=" + oktaId;
    return await app.$ravenapi.post(endpoint, {});
  }
}

export const userManagementService = new UserManagementService();

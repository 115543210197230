import {
  InvoicesListPath,
  OptionsObject,
  SortOptions
} from "@/models/invoices-list/invoice-list-table-data";
import moment from "moment";
import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";

@Module({ name: "InvoiceListStore", namespaced: true })
class InvoiceListStore extends VuexModule {
  private invoicesListPathObject: InvoicesListPath = {
    invoicesListScreenString: "",
    invoicesGroupString: "",
    invoicesStatusString: ""
  };
  private filters: { [key: string]: any } = {};
  private searchString = "";
  private tabFilter = "";
  private invIDsCandidates: number[] = [];
  private selectedStateString = "";

  private optionsObject: OptionsObject = {
    groupBy: [],
    groupDesc: [],
    itemsPerPage: 10,
    multiSort: true,
    mustSort: false,
    page: 1,
    sortBy: [],
    sortDesc: []
  };

  private tableOrder: { [key: string]: SortOptions } = {};

  // date range is initialized to current business week to prevent making query for a large number of completed invoices
  private dateRange: { [key: string]: string } = this.defaultDateRange;
  public get defaultDateRange() {
    const day = new Date().getDay();
    const daysSinceMonday = day === 0 ? 6 : day - 1;
    const lastMonday = moment()
      .subtract(daysSinceMonday, "days")
      .format("YYYY-MM-DD");
    const today = moment()
      .utc()
      .format("YYYY-MM-DD");
    return { startDate: lastMonday, endDate: today };
  }

  @Mutation
  private setInvoicesListPathObject(path: InvoicesListPath) {
    this.invoicesListPathObject = path;
  }

  @Mutation
  private setFilterObject(filters: { [key: string]: any }) {
    this.filters = filters;
  }

  @Mutation
  private setTabFilterObject(tabFilter: string) {
    this.tabFilter = tabFilter;
  }

  @Mutation
  private setSearchString(search: string) {
    this.searchString = search;
  }

  @Mutation
  private setOptionsObject(optionsObject: OptionsObject) {
    this.optionsObject = optionsObject;
  }

  @Mutation
  private setInvIDsCandidatesArray(candidates: number[]) {
    this.invIDsCandidates = candidates;
  }

  @Mutation
  private setSelectedStateString(selectedState: string) {
    this.selectedStateString = selectedState;
  }

  @Action
  public updateInvoicesListPathObject(path: InvoicesListPath) {
    this.context.commit("setInvoicesListPathObject", path);
  }

  @Mutation
  private setDateRange(dateRange: { [key: string]: string }) {
    this.dateRange = dateRange;
  }

  @Mutation
  private addUpdateTableSortItem(item: { key: string; value: SortOptions }) {
    this.tableOrder[item.key] = item.value;
  }

  @Action
  public updateFilterObject(filters: { [key: string]: any }) {
    this.context.commit("setFilterObject", filters);
  }

  @Action
  public updateTabFilterObject(tabFilter: string) {
    this.context.commit("setTabFilterObject", tabFilter);
  }

  @Action
  public updateSearchString(search: string) {
    this.context.commit("setSearchString", search);
  }

  @Action
  public updateInvIDsCandidatesArray(candidates: number[]) {
    this.context.commit("setInvIDsCandidatesArray", candidates);
  }

  @Action
  public updateOptionsObject(optionsObject: OptionsObject) {
    this.context.commit("setOptionsObject", optionsObject);
  }

  @Action
  public updateSelectedStateString(selectedState: string) {
    this.context.commit("setSelectedStateString", selectedState);
  }

  @Action({ rawError: true })
  public updateDateRange(dateRange: { [key: string]: string }) {
    this.context.commit("setDateRange", dateRange);
  }

  @Action
  public updateTableOrderObject(item: { key: string; value: SortOptions }) {
    this.context.commit("addUpdateTableSortItem", item);
  }

  public get getStoredInvoicesListPathObject(): InvoicesListPath {
    return this.invoicesListPathObject;
  }

  public get getStoredFilterObject(): { [key: string]: any } {
    return this.filters;
  }

  public get getStoredTabFilterString(): string {
    return this.tabFilter;
  }

  public get getStoredSearchString(): string {
    return this.searchString;
  }

  public get getStoredOptionsObject(): OptionsObject {
    return this.optionsObject;
  }

  public get getStoredInvIDsCandidates(): number[] {
    return this.invIDsCandidates;
  }

  public get getStoredSelectedState(): string {
    return this.selectedStateString;
  }

  public get getDateRange(): { [key: string]: string } {
    return this.dateRange;
  }

  public get getStoredTableOrderObject(): { [key: string]: SortOptions } {
    return this.tableOrder;
  }
}

export default InvoiceListStore;

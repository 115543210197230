import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";

@Module({ name: "NavSidebarStore", namespaced: true })
class NavSidebarStore extends VuexModule {
  private sidebarShow = true;
  private sidebarMinimize = false;

  @Mutation
  private setSidebarShowMutation(sidebarShow: boolean) {
    this.sidebarShow = sidebarShow;
  }

  @Mutation
  private setSidebarMinimizeMutation(sidebarMinimize: boolean) {
    this.sidebarMinimize = sidebarMinimize;
  }

  @Action
  public setSidebarShow(sidebarShow: boolean) {
    this.context.commit("setSidebarShowMutation", sidebarShow);
  }

  @Action
  public setSidebarMinimize(sidebarMinimize: boolean) {
    this.context.commit("setSidebarMinimizeMutation", sidebarMinimize);
  }

  public get getSidebarShow() {
    return this.sidebarShow;
  }

  public get getSidebarMinimize() {
    return this.sidebarMinimize;
  }
}

export default NavSidebarStore;

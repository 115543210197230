import Vue from "vue";
import VueRouter, { Route, RouteConfig } from "vue-router";
import Constants from "@/shared/constants/website";
import { OktaAuth } from "@okta/okta-auth-js";
import OktaVue from "@okta/okta-vue";
import oktaConfig from "@/okta-config";
import { baseRoutes } from "./base-routes";
import { userRoutes } from "./users-routes";
import { settingsRoutes } from "./settings-routes";
import { documentsRoutes } from "./documents-routes";
import { analyticsRoutes } from "./analytics-routes";
import { validationServicesRoutes } from "./validation-services-routes";
import { paymentsRoutes } from "./payments-routes";
import { internalOnlyRoutes } from "./internal-only-routes";
import store from "@/store/index";
import {
  customPermissions,
  isAscendAdmin,
  isValidationServicesSpecialist,
  userHasAppRoles,
  userRolesIncludesRole
} from "@/helpers/user-roles";
import { checkCurrentRouteAndRedirect } from "@/helpers/router-helpers";
import { app } from "@/main";

Vue.use(VueRouter);

const oktaAuth = new OktaAuth(oktaConfig.oidc);
Vue.use(OktaVue, { oktaAuth });

oktaAuth.tokenManager?.on("error", async function(error: any) {
  // if there's an error on token renewal, sign in again
  await oktaAuth.signInWithRedirect();
});

// merge all routes into same array
const routes: Array<RouteConfig> = [
  ...userRoutes,
  ...analyticsRoutes,
  ...settingsRoutes,
  ...documentsRoutes,
  ...baseRoutes,
  ...validationServicesRoutes,
  ...paymentsRoutes,
  ...internalOnlyRoutes
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

function hasPermissionsNeeded(user: any, to: Route) {
  const routeAuthorizedRoles = to.meta.authorize;
  if (to.meta.customPermissions) {
    let authorized = false;
    (to.meta.customPermissions as string[]).forEach((group: string) => {
      if (customPermissions[group]()) authorized = true;
    });
    // also check if user role permissions are needed for custom permission routes
    if ((routeAuthorizedRoles ?? []).length > 0) {
      authorized =
        authorized && userRolesIncludesRole(user, routeAuthorizedRoles);
    }
    return authorized;
  } else if (!user.approle) {
    // Basic validation to check if user does not contain any approles
    return false;
  }
  // Validation to check if user has the app role needed to access specific AscendAP route
  return userRolesIncludesRole(user, routeAuthorizedRoles);
}

function redirectToValidationServicesRoute(user: any, to: Route) {
  if (
    isValidationServicesSpecialist(user) &&
    !to.path.startsWith("validation-services") &&
    !to.path.startsWith("/document/")
  ) {
    return true;
  }
  return false;
}

async function checkApiHealth(): Promise<boolean> {
  try {
    const isHealthy = await app.$ravenapi.get("/api/healthcheck");
    return isHealthy.status === 200 ? true : false;
  } catch (error) {
    return false;
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
router.beforeEach(async (to, from, next) => {
  if (to.meta.requiresAuth && !(await oktaAuth.isAuthenticated())) {
    await oktaAuth.signInWithRedirect();
  } else if (to.meta.authorize || to.meta.customPermissions) {
    const user = store.getters["UserStore/getUser"];
    const apiIsHealthy = store.getters["UserStore/getApiIsHealthy"];
    if (!apiIsHealthy) {
      const isHealthy = await checkApiHealth();
      store.commit("UserStore/setApiIsHealthyMutation", isHealthy);
    }

    if (store.getters["UserStore/getApiIsHealthy"] === false) {
      // send user to error page if API is down
      checkCurrentRouteAndRedirect(router, "/503/healthcheck");
    } else if (!user && from.path.startsWith("/login/callback")) {
      // allow user to proceed to finalize token processing
      next();
    } else if (!userHasAppRoles(user)) {
      checkCurrentRouteAndRedirect(router, "/403/permission");
    } else if (hasPermissionsNeeded(user, to)) {
      // user is authorized for route, proceed to route
      next();
    } else if (redirectToValidationServicesRoute(user, to)) {
      // redirect VS_Specialist role from homepage to validation services landing page
      checkCurrentRouteAndRedirect(router, "/validation-services");
    } else {
      // user is not authorized for route, redirect to error page
      checkCurrentRouteAndRedirect(router, "/403/access");
    }
  } else {
    // route doesn't require authorization, proceed to route
    next();
  }
  document.title = !to.meta.title
    ? Constants.WEBSITE_NAME
    : `${Constants.WEBSITE_NAME} - ${to.meta.title}`;
});

export default router;


import { Component } from "vue-property-decorator";
import UserRolesMixin from "./UserRoles.vue";

@Component
export default class UpdateApplicationMixin extends UserRolesMixin {
  private registration: ServiceWorkerRegistration | null = null;
  private updateExists = false;
  private refreshing = false;

  created() {
    // listen for custom event from service worker registration
    document.addEventListener("serviceWorkerUpdated", this.updateAvailable, {
      once: true
    });

    // confirm browser support for SWs
    if ("serviceWorker" in navigator) {
      // prevent multiple refreshes
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        if (this.refreshing) return;
        this.refreshing = true;

        // reload the page
        window.location.reload();
      });
    }
  }

  updateAvailable(event: any) {
    // store the service worker registration so we can send it a message
    this.registration = event.detail;
    // updateExists is used to control the dialog used to alert the user that
    // there is an update they need to refresh for
    this.updateExists = true;
  }

  // reloadApp is called when user accepts the update
  reloadApp() {
    this.updateExists = false;
    // only send a "skip waiting" message if the service worker is waiting
    if (!this.registration || !this.registration.waiting) return;
    // send message to service worker to skip the waiting and activate the new service worker
    this.registration.waiting.postMessage("SKIP_WAITING");
  }
}

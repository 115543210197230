















import { Component, Vue, Prop } from "vue-property-decorator";
import moment from "moment";

@Component
export default class ChatbotHeader extends Vue {
  @Prop({ default: false }) isSizedForChatResponse?: boolean;

  private get currentTimestamp(): string {
    const locale = Intl.DateTimeFormat().resolvedOptions().locale;
    moment.locale(locale.toLowerCase());
    return moment().format("LT");
  }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-menu',{attrs:{"bottom":"","left":"","close-on-content-click":false,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"id":"activator-button","ripple":false}},'v-icon',attrs,false),on),[_vm._v(" mdi-message-text ")])]}}]),model:{value:(_vm.isChatbotOpen),callback:function ($$v) {_vm.isChatbotOpen=$$v},expression:"isChatbotOpen"}},[_c('v-card',[_c('v-system-bar',{staticClass:"chatbot-control-panel",attrs:{"fixed":""}},[_c('chatbot-header'),_c('icon-button',{attrs:{"materialIconToDisplay":"mdi-minus","handleClick":_vm.endChat,"tooltipText":"Minimize","btnClass":"chat-icon-button"}})],1),_c('div',{staticClass:"conversation-wrapper"},[_c('div',{attrs:{"id":"conversation"}},_vm._l((_vm.chatTransactions),function(transaction){return _c('div',{key:'transaction-wrapper' + transaction.id,staticClass:"d-flex flex-column"},[(transaction.to == 'chatbot')?_c('user-chat-request-header',{key:transaction.id,staticClass:"align-self-end"}):_c('chatbot-header',{key:transaction.id,attrs:{"isSizedForChatResponse":true}}),_c('div',{key:'messages-wrapper' + transaction.id,staticClass:"messages-wrapper"},_vm._l((transaction.messages),function(message){return _c('div',{key:message.id,class:{
                  userRequest: transaction.to == 'chatbot',
                  lexResponse:
                    transaction.to == 'user' && !transaction.hasError,
                  lexError: transaction.to == 'user' && transaction.hasError
                }},[_c('p',{key:'p-message' + message.id,domProps:{"innerHTML":_vm._s(message.content)}})])}),0),(transaction.to == 'chatbot')?_c('h2',{key:'sent-message' + transaction.id,staticClass:"align-self-end chat-response-footer"},[_vm._v(" Sent ")]):_vm._e()],1)}),0),(_vm.isLoadingResponse)?_c('v-progress-linear',{staticClass:"loading",attrs:{"stream":"","buffer-value":"0"}}):_vm._e()],1),_c('v-card-actions',[_c('v-text-field',{attrs:{"id":"wisdom","placeholder":"Ask me a question","disabled":_vm.isChatInputLocked,"autocomplete":"off"},on:{"keydown":_vm.pushChatOnEnterKeystroke},model:{value:(_vm.chatInput),callback:function ($$v) {_vm.chatInput=$$v},expression:"chatInput"}}),_c('icon-button',{attrs:{"materialIconToDisplay":"mdi-send","handleClick":_vm.pushChat,"tooltipText":"Send Request","btnClass":"chat-icon-button","isReadOnly":_vm.isChatInputLocked}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { cloneDeep } from "lodash";
import moment from "moment";
import { InputObject } from "@/models/document-entry/input-object";
import { TableColumnSelector } from "@/models/payments/table-column-selector";
import { PaymentActivityRecord } from "@/models/payments/payment-activity/payment-activity-record";
import { PaymentPaginationMetadata } from "@/models/payments/payment-pagination-metadata";

@Module({ name: "PaymentActivityStore", namespaced: true })
class PaymentActivityStore extends VuexModule {
  private customerID: number | null = null;
  private startDate = moment()
    .subtract(1, "months")
    .format()
    .toString()
    .substring(0, 10);
  private endDate = moment()
    .format()
    .toString()
    .substring(0, 10);
  private companyOptions: InputObject[] = [];
  private paymentTypeOptions: InputObject[] = [];
  private paymentStatusOptions: InputObject[] = [];
  private selectedCompany: string | null = null;
  private selectedPaymentType: string | null = null;
  private selectedPaymentStatus: string | null = null;
  private readonly defaultHeaders: TableColumnSelector[] = [
    {
      align: "end",
      text: "Amount",
      sortable: true,
      value: "amount",
      selected: true
    },
    {
      align: "start",
      text: "Date",
      sortable: true,
      value: "payment_date",
      selected: true
    },
    {
      align: "start",
      text: "Payment Status",
      sortable: true,
      value: "payment_status_type",
      selected: true
    },
    {
      align: "start",
      text: "Company",
      sortable: true,
      value: "company_name",
      selected: true
    },
    {
      align: "start",
      text: "Created",
      sortable: true,
      value: "batched_created_at",
      selected: false
    },
    {
      align: "start",
      text: "Notes",
      sortable: true,
      value: "payment_descriptor",
      selected: false
    },
    {
      align: "start",
      text: "Payee",
      sortable: true,
      value: "payee_name",
      selected: false
    },
    {
      align: "start",
      text: "Remittance Email",
      sortable: true,
      value: "payee_contact_email",
      selected: false
    },
    {
      align: "start",
      text: "Reconciled",
      sortable: true,
      value: "provider_payment_status_type",
      selected: false
    },
    {
      align: "start",
      text: "Payment Type",
      sortable: true,
      value: "payment_type",
      selected: false
    },
    {
      align: "start",
      text: "Supplier ID",
      sortable: true,
      value: "payee_id",
      selected: false
    },
    {
      align: "start",
      text: "Remit To",
      sortable: true,
      value: "payee_remit_id",
      selected: false
    },
    {
      align: "start",
      text: "WD Settlement Run #",
      sortable: true,
      value: "run_id",
      selected: false
    },
    {
      align: "start",
      text: "WD Settlement Date",
      sortable: true,
      value: "run_date",
      selected: false
    },
    {
      align: "end",
      text: "WD Total Settlement Amount",
      sortable: true,
      value: "run_total",
      selected: false
    }
  ];
  private availableHeaders: TableColumnSelector[] = cloneDeep(
    this.defaultHeaders
  );
  private payments: PaymentActivityRecord[] = [];
  private paginationMetadata: PaymentPaginationMetadata | null = null;
  private pagesLoaded: number[] = [];
  private selectedPayment: PaymentActivityRecord | null = null;

  @Mutation
  private setStartDateMutation(date: string) {
    this.startDate = date;
  }

  @Mutation
  private setEndDateMutation(date: string) {
    this.endDate = date;
  }

  @Mutation
  private setCompanyOptionsMutation(options: InputObject[]) {
    this.companyOptions = options;
  }

  @Mutation
  private setPaymentTypeOptionsMutation(options: InputObject[]) {
    this.paymentTypeOptions = options;
  }

  @Mutation
  private setPaymentStatusOptionsMutation(options: InputObject[]) {
    this.paymentStatusOptions = options;
  }

  @Mutation
  private setSelectedCompanyMutation(option: string | null) {
    this.selectedCompany = option;
  }

  @Mutation
  private setSelectedPaymentTypeMutation(option: string | null) {
    this.selectedPaymentType = option;
  }

  @Mutation
  private setSelectedPaymentStatusMutation(option: string | null) {
    this.selectedPaymentStatus = option;
  }

  @Mutation
  private setAvailableHeadersMutation(tableHeaders: TableColumnSelector[]) {
    this.availableHeaders = tableHeaders;
  }

  @Mutation
  private setPaymentsMutation(payments: PaymentActivityRecord[]) {
    this.payments = payments;
  }

  @Mutation
  private setPaginationMetadataMutation(
    metadata: PaymentPaginationMetadata | null
  ) {
    this.paginationMetadata = metadata;
  }

  @Mutation
  private setPagesLoadedMutation(pagesLoaded: number[]) {
    this.pagesLoaded = pagesLoaded;
  }

  @Mutation
  private setSelectedPaymentMutation(payment: PaymentActivityRecord | null) {
    this.selectedPayment = payment;
  }

  @Mutation
  private resetStateMutation() {
    this.startDate = moment()
      .subtract(1, "months")
      .format()
      .toString()
      .substring(0, 10);
    this.endDate = moment()
      .format()
      .toString()
      .substring(0, 10);
    this.companyOptions = [];
    this.paymentTypeOptions = [];
    this.paymentStatusOptions = [];
    this.selectedCompany = null;
    this.selectedPaymentType = null;
    this.selectedPaymentStatus = null;
    this.payments = [];
    this.paginationMetadata = null;
    this.pagesLoaded = [];
    this.selectedPayment = null;
  }

  @Mutation
  private setCustomerMutation(customerID: number) {
    this.customerID = customerID;
  }

  @Action({ rawError: true })
  public setStartDate(date: string) {
    this.context.commit("setStartDateMutation", date);
  }

  @Action({ rawError: true })
  public setEndDate(date: string) {
    this.context.commit("setEndDateMutation", date);
  }

  @Action({ rawError: true })
  public setCompanyOptions(options: InputObject[]) {
    this.context.commit("setCompanyOptionsMutation", options);
  }

  @Action({ rawError: true })
  public setPaymentTypeOptions(options: InputObject[]) {
    this.context.commit("setPaymentTypeOptionsMutation", options);
  }

  @Action({ rawError: true })
  public setPaymentStatusOptions(options: InputObject[]) {
    this.context.commit("setPaymentStatusOptionsMutation", options);
  }

  @Action({ rawError: true })
  public setSelectedCompany(option: string | null) {
    this.context.commit("setSelectedCompanyMutation", option);
  }

  @Action({ rawError: true })
  public setSelectedPaymentType(option: string | null) {
    this.context.commit("setSelectedPaymentTypeMutation", option);
  }

  @Action({ rawError: true })
  public setSelectedPaymentStatus(option: string | null) {
    this.context.commit("setSelectedPaymentStatusMutation", option);
  }

  @Action({ rawError: true })
  public setAvailableHeaders(tableHeaders: TableColumnSelector[]) {
    this.context.commit("setAvailableHeadersMutation", tableHeaders);
  }

  @Action({ rawError: true })
  public setPayments(payments: PaymentActivityRecord[]) {
    this.context.commit("setPaymentsMutation", payments);
  }

  @Action({ rawError: true })
  public setPaginationMetadata(metadata: PaymentPaginationMetadata | null) {
    this.context.commit("setPaginationMetadataMutation", metadata);
  }

  @Action({ rawError: true })
  public setPagesLoaded(pagesLoaded: number[]) {
    this.context.commit("setPagesLoadedMutation", pagesLoaded);
  }

  @Action({ rawError: true })
  public setSelectedPayment(payment: PaymentActivityRecord | null) {
    this.context.commit("setSelectedPaymentMutation", payment);
  }

  @Action({ rawError: true })
  public resetState() {
    this.context.commit("resetStateMutation");
  }

  @Action({ rawError: true })
  public setCustomer(customerID: number | null) {
    this.context.commit("setCustomerMutation", customerID);
  }

  public get getStartDate(): string {
    return this.startDate;
  }

  public get getEndDate(): string {
    return this.endDate;
  }

  public get getCompanyOptions(): InputObject[] {
    return this.companyOptions;
  }

  public get getPaymentTypeOptions(): InputObject[] {
    return this.paymentTypeOptions;
  }

  public get getPaymentStatusOptions(): InputObject[] {
    return this.paymentStatusOptions;
  }

  public get getSelectedCompany(): string | null {
    return this.selectedCompany;
  }

  public get getSelectedPaymentType(): string | null {
    return this.selectedPaymentType;
  }

  public get getSelectedPaymentStatus(): string | null {
    return this.selectedPaymentStatus;
  }

  public get getDefaultHeaders(): TableColumnSelector[] {
    return this.defaultHeaders;
  }

  public get getAvailableHeaders(): TableColumnSelector[] {
    return this.availableHeaders;
  }

  public get getPayments(): PaymentActivityRecord[] {
    return this.payments;
  }

  public get getPaginationMetadata(): PaymentPaginationMetadata | null {
    return this.paginationMetadata;
  }

  public get getPagesLoaded(): number[] {
    return this.pagesLoaded;
  }

  public get getSelectedPayment(): PaymentActivityRecord | null {
    return this.selectedPayment;
  }

  public get getCustomerID(): number | null {
    return this.customerID;
  }
}

export default PaymentActivityStore;

/* eslint-disable @typescript-eslint/camelcase */

export class PaymentsOnboardingFormStatus {
  public onboarding_form_completed: boolean;
  public onboarding_form_locked_at: string;
  public user_requesting_onboarding: string;

  constructor(
    onboarding_form_completed: boolean,
    onboarding_form_locked_at: string,
    user_requesting_onboarding: string
  ) {
    this.onboarding_form_completed = onboarding_form_completed;
    this.onboarding_form_locked_at = onboarding_form_locked_at;
    this.user_requesting_onboarding = user_requesting_onboarding;
  }
}

import { render, staticRenderFns } from "./AIChatbot.vue?vue&type=template&id=2d0d1c22&scoped=true&"
import script from "./AIChatbot.vue?vue&type=script&lang=ts&"
export * from "./AIChatbot.vue?vue&type=script&lang=ts&"
import style0 from "./AIChatbot.vue?vue&type=style&index=0&id=2d0d1c22&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d0d1c22",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSystemBar } from 'vuetify/lib/components/VSystemBar';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCard,VCardActions,VIcon,VMenu,VProgressLinear,VSystemBar,VTextField})

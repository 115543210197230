

























import { Component, Watch } from "vue-property-decorator";
import { checkCurrentRouteAndRedirect } from "@/helpers/router-helpers";
import { AccessToken, IDToken, TokenManager } from "@okta/okta-auth-js";
import * as Sentry from "@sentry/vue";
import AppRoles from "@/shared/constants/application-roles";
import UpdateApplicationMixin from "./mixins/UpdateApplication.vue";
import { isAscendUser } from "@/helpers/user-roles";
import { customerService } from "@/services/customer.service";
import { userManagementService } from "./services/user-management.service";
import SimpleConfirmationModal from "@/components/design-system/modals/SimpleConfirmationModal.vue";

const pendo = (window as any).pendo;

@Component({
  components: {
    "simple-confirmation-modal": SimpleConfirmationModal
  }
})
export default class App extends UpdateApplicationMixin {
  private key = this.$route.fullPath; // this is needed to refresh component

  // determine which layout to display based on route metadata
  private get layout() {
    return (this.$route.meta.layout || "default") + "-layout";
  }
  private get layoutSpecificId() {
    if (this.$route.meta.layout === "minimal") {
      return "minimal-background";
    } else if (this.$route.meta.layout === "error") {
      return "error-background";
    } else {
      return "default-background";
    }
  }

  async mounted() {
    // check for tokens from redirect
    const tokenManager: TokenManager = this.$auth.tokenManager;
    const accessToken: AccessToken = (await tokenManager.get(
      "accessToken"
    )) as AccessToken;
    const idToken: IDToken = (await tokenManager.get("idToken")) as IDToken;

    if (accessToken) {
      this.userStore.setAccessToken(accessToken.accessToken);
    }
    if (idToken) {
      this.userStore.setIdToken(idToken.idToken);
      this.userStore.setUser(idToken.claims);

      // configure Sentry to understand user
      Sentry.configureScope(function(scope: any) {
        scope.setUser({
          email: idToken.claims.email,
          name: idToken.claims.name
        });
      });

      // initialize Pendo
      pendo.initialize({
        visitor: {
          id: idToken.claims.email?.toLowerCase(), // Required if user is logged in
          email: idToken.claims.email?.toLowerCase(), // Recommended if using Pendo Feedback, or NPS Email
          // eslint-disable-next-line @typescript-eslint/camelcase
          full_name: idToken.claims.name, // Recommended if using Pendo Feedback
          role: idToken.claims.approle ? idToken.claims.approle : [] // Optional

          // You can add any additional visitor level key-values here,
          // as long as it's not one of the above reserved names.
        },
        account: {
          id: isAscendUser(idToken.claims)
            ? AppRoles.ASCEND_USER
            : idToken.claims.customerid,
          name: isAscendUser(idToken.claims)
            ? AppRoles.ASCEND_USER
            : idToken.claims.groups
                .filter((group: string) => group.includes("USERS_"))[0]
                .substring(6) // Store name following USERS_ as Account Name

          // You can add any additional account level key-values here,
          // as long as it's not one of the reserved names (id, name,
          // is_paying_ monthly_value, planLevel, planPrice, creationDate).
        }
      });

      let customerRegion = process.env.VUE_APP_REGION;
      if (!this.isAscendUser && accessToken) {
        // Ascend users will choose a customer after mounted()
        // and the customer's region is stored at that time
        customerRegion = await customerService.getCustomerRegion();
        const customerShortName = userManagementService.getCustomerShortname(
          idToken.claims.groups
        );
        this.userStore.setSelectedCustomer({
          id: idToken.claims.customerid,
          displayValue: customerShortName,
          description: customerShortName,
          region: customerRegion
        });
      }
      // update launch-darkly client for current signed in user
      await this.$launchDarkly.identify({
        key: idToken.claims.email,
        name: idToken.claims.name,
        email: idToken.claims.email,
        custom: {
          groups: idToken.claims.groups,
          approle: idToken.claims.approle ? idToken.claims.approle : [],
          customerid: idToken.claims.customerid,
          region: customerRegion,
          env: process.env.VUE_APP_ENVIRONMENT
        },
        anonymous: false
      });
    }
    this.key += "/"; // force component render after flags load
  }

  @Watch("$route", { deep: true })
  async redirectToSelectCustomer() {
    // check current auth status and update store
    const isAuthenticated = await this.$auth.isAuthenticated();
    this.userStore.updateAuthStatus(isAuthenticated);
    // push Ascend user to select customer in prod
    if (this.userStore.getUser) {
      if (!this.shouldMakeDataRequest) {
        checkCurrentRouteAndRedirect(
          this.$router,
          "/internal-only/select-customer"
        );
      }
    }
  }
}
